import React from 'react';
import { ContentSection, Heading } from '@vwfs-its/bronson-react';
import { useTranslation } from 'react-i18next';
import { OpenRequest } from 'components/open-request/OpenRequest';

export const OpenRequestPage: React.FC = () => {
    const { t } = useTranslation('open-request');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('header')}</Heading>
            <OpenRequest />
        </ContentSection>
    );
};
