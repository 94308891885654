export interface RegistrationBankIDPayload {
    portalBaseUrl: string;
}

export interface RegistrationBankIDResponse {
    url: string;
}

export interface RegistrationBankIDVerifyPayload {
    caseId: string;
    xTrackingId: string;
}

export enum RegistrationBankIDErrorCode {
    BANK_ID_API_ERROR = 'BANK_ID_API_ERROR',
    CUSTOMER_ALREADY_REGISTERED = 'CUSTOMER_ALREADY_REGISTERED',
    MISSING_PORTAL_BASE_URL = 'MISSING_PORTAL_BASE_URL',
}

export enum RegistrationBankIDVerifyErrorCode {
    BANK_ID_API_ERROR = 'BANK_ID_API_ERROR',
    CUSTOMER_ALREADY_REGISTERED = 'CUSTOMER_ALREADY_REGISTERED',
    CUSTOMER_DATA_DONOT_MATCH = 'CUSTOMER_DATA_DONOT_MATCH',
    TIME_LIMIT_EXCEED = 'TIME_LIMIT_EXCEED',
    CUSTOMER_DATA_NOT_FOUND = 'CUSTOMER_DATA_NOT_FOUND',
}

export enum BankIDErrorCode {
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    ADEL_BANKID_UNAVAILABLE = 'ADEL_BANKID_UNAVAILABLE',
    ADEL_CASEID_NOT_RECOGNIZED = 'ADEL_CASEID_NOT_RECOGNIZED',
    ADEL_CASE_SCOPE_NOT_RECOGNIZED = 'ADEL_CASE_SCOPE_NOT_RECOGNIZED',
    ADEL_CASE_SCOPE_UNKNOWN = 'ADEL_CASE_SCOPE_UNKNOWN',
    ADEL_EXTERNAL_CASE_ID_NOT_RECOGNIZED = 'ADEL_EXTERNAL_CASE_ID_NOT_RECOGNIZED',
    ADEL_INVALID_FORMAT = 'ADEL_INVALID_FORMAT',
    ADEL_BANKID_ACCESS_DENIED = 'ADEL_BANKID_ACCESS_DENIED',
    ADEL_EXTERNAL_CASEID_ALREADY_EXISTS = 'ADEL_EXTERNAL_CASEID_ALREADY_EXISTS',
    ADEL_OPERATION_NOT_SUPPORTED = 'ADEL_OPERATION_NOT_SUPPORTED',
    ADEL_TIMEOUT = 'ADEL_TIMEOUT',
    ADEL_INTERNAL_ERROR = 'ADEL_INTERNAL_ERROR',
    ADEL_DB_RECORD_CREATE_FAILED = 'ADEL_DB_RECORD_CREATE_FAILED',
    ADEL_WRONG_TRACKING_ID = 'ADEL_WRONG_TRACKING_ID',
}
