import { CookiePolicy, getCookiePolicyEndpoint } from '@cp-cz/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<CookiePolicy>({
    contentName: 'cookiePolicy',
    contentEndpoint: getCookiePolicyEndpoint,
});

export default reducer;
export const fetchCookiePolicy = fetchContent;
