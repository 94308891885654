import React, { useEffect } from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { ContractsUi } from './ui';
import { ContractsLoadingPlaceholder, useAnalyticsDashboardPageTrackerWithoutUnpaid } from '@cp-shared/frontend-ui';
import { isAfterLoginLocalStorageKey } from '../../config';
import { useLastLocation } from 'react-router-last-location';
import { loginPath, registrationPagePath } from '../navigation/paths';
import { Contract } from '@cp-cz/common';
import { useMyProfile } from '../my-profile/useMyProfile';

const ContractsWithHandlers = withLoadingAndNoConnectionHandler(ContractsUi);

type ContractsProps = {
    contracts?: Contract[];
    isLoadingContracts: boolean;
    contractsLoadingError: boolean;
};

export const Contracts: React.FC<ContractsProps> = ({ contracts = [], isLoadingContracts, contractsLoadingError }) => {
    const isAfterLogin = !!window.localStorage.getItem(isAfterLoginLocalStorageKey);
    const lastLocation = useLastLocation();
    const lastPathname = isAfterLogin
        ? loginPath()
        : lastLocation?.pathname.includes(registrationPagePath())
        ? registrationPagePath()
        : lastLocation?.pathname;
    useAnalyticsDashboardPageTrackerWithoutUnpaid(!contractsLoadingError, lastPathname || '');

    const { data: myProfileData, isLoading: isLoadingMyProfile } = useMyProfile();

    useEffect(() => {
        if (isAfterLogin) {
            window.localStorage.removeItem(isAfterLoginLocalStorageKey);
        }
    }, [isAfterLogin]);

    return (
        <ContractsWithHandlers
            isLoading={isLoadingContracts || isLoadingMyProfile}
            contracts={contracts}
            phoneNumber={myProfileData?.contactDetails?.phoneNumber}
            hasError={contractsLoadingError}
            loadingPlaceholder={<ContractsLoadingPlaceholder />}
        />
    );
};
