export function loginPath(): string {
    return '/login';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function bankIDVerificationPagePath(): string {
    return '/register/bank-id';
}

export function vwfsVerificationPagePath(): string {
    return '/register/vwfs';
}

export function invitationPagePath(): string {
    return '/invitation';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function thirdPartyLicensesPagePath(): string {
    return '/licenses';
}

export function landingPagePath(): string {
    return '/';
}

export function loginRedirectPagePath(): string {
    return '/login-redirect';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeContactDetailsPath(): string {
    return '/my-profile/change-contact-details';
}

export function myDocumentsPagePath(): string {
    return '/my-documents';
}

export function myRequestsPagePath(): string {
    return '/my-requests';
}

export function amortizationTablePath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/amortization-table/${encryptedContractNumber}`;
}

export function changeContactAddressPath(): string {
    return '/my-profile/change-contact-address';
}

export function changeMainAddressPath(): string {
    return '/my-profile/change-main-address';
}

export function hookPageRedirectionPath(): string {
    return '/hookpage';
}

export function contactDetailsHookPagePath(): string {
    return '/hookpage/contact-details';
}

export function marketingPreferencesHookPagePath(): string {
    return '/hookpage/marketing-preferences';
}

export function consentHookPagePath(): string {
    return '/terms-and-conditions-consent-hookpage';
}
