import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionItem, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared/frontend-ui';
import { ContractParties } from '@cp-cz/common';

export const ContractPartiesDetails: React.FC<{ contractParties?: ContractParties }> = ({ contractParties }) => {
    const { t } = useTranslation('contract-parties');
    if (!contractParties?.salesPersonName) {
        return null;
    }

    const item: DefinitionListItem[] = [
        {
            label: t('sales-person.label'),
            value: contractParties.salesPersonName || '',
        },
    ];

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <DefinitionListHorizontal list={item} />
        </AccordionItem>
    );
};
