import React from 'react';
import { CoverageCode, CoverageDesc, InsuranceCoverage } from '@cp-cz/common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared/frontend-ui';
import { useTranslationWithFormatting, TranslationFormat } from 'localization/useTranslationWithFormatting';

type SupplementaryInsuranceSectionProps = {
    coverages: InsuranceCoverage[];
};

export const SupplementaryInsuranceSection: React.FC<SupplementaryInsuranceSectionProps> = ({ coverages }) => {
    const { t, f } = useTranslationWithFormatting('insurance-details');
    const translationPrefix = 'supplementary-insurance-section';

    const mapCoverageWindowVariantCode = (coverageWindowVariantCode?: string): string | undefined => {
        switch (coverageWindowVariantCode) {
            case 'VSESKL':
                return t(`${translationPrefix}.all-windows`);
            case 'CELSKL':
                return t(`${translationPrefix}.windshield`);
            default:
                return undefined;
        }
    };

    const getListItems = () =>
        coverages
            .map((coverage) => {
                const {
                    coverageCode,
                    coverageDesc,
                    coverageEndDate,
                    coverageExpectedEndDate,
                    replacementVehicleDays,
                    coverageWindowVariantCode,
                } = coverage;
                const endDate = coverageEndDate || coverageExpectedEndDate;
                const formattedEndDate = endDate ? f(endDate, TranslationFormat.DATE) : undefined;
                const isReplacementVehicle =
                    coverageCode === CoverageCode.NAV || coverageDesc === CoverageDesc.REPLACEMENT_VEHICLE;
                const isWindshieldCoverage =
                    coverageCode === CoverageCode.SKL || coverageDesc === CoverageDesc.WINDSHIELD_COVERAGE;
                const coverageWindowVariant = mapCoverageWindowVariantCode(coverageWindowVariantCode);

                if (isReplacementVehicle)
                    return {
                        label: t(`${translationPrefix}.replacement-vehicle-label`),
                        value:
                            replacementVehicleDays && formattedEndDate
                                ? t(`${translationPrefix}.replacement-vehicle-value`, {
                                      replacementVehicleDays,
                                      endDate: formattedEndDate,
                                  })
                                : '-',
                        testId: 'replacement-vehicle',
                    };

                if (isWindshieldCoverage)
                    return {
                        label: t(`${translationPrefix}.windshield-coverage-label`),
                        value:
                            coverageWindowVariant && formattedEndDate
                                ? t(`${translationPrefix}.windshield-coverage-value`, {
                                      coverageWindowVariant: coverageWindowVariant,
                                      endDate: formattedEndDate,
                                  })
                                : '-',
                        testId: 'windshield-coverage',
                    };

                return {
                    label: coverageDesc,
                    value: formattedEndDate ? t(`${translationPrefix}.end-date`, { endDate: formattedEndDate }) : '-',
                    testId: 'coverage-end-date',
                };
            })
            .filter(({ label }) => !!label);

    const itemList: DefinitionListItem[] = getListItems();

    if (!itemList.filter(({ value }) => value !== '-').length) return null;

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)} withoutCardEffect>
            <DefinitionListHorizontal list={itemList} />
        </DataOverview>
    );
};
