import { CountryCode } from '../../apis/services/types/my-profile/contact-details';

export const countryCodes: CountryCode[] = [
    {
        code: 'CZ',
        name: 'Česko',
        dialCode: '420',
    },
    {
        code: 'SK',
        name: 'Slovensko',
        dialCode: '421',
    },
    {
        code: 'AF',
        name: 'Afghánistán',
        dialCode: '93',
    },
    {
        code: 'AX',
        name: 'Alandské ostrovy',
        dialCode: '358',
    },
    {
        code: 'AL',
        name: 'Albánie',
        dialCode: '355',
    },
    {
        code: 'DZ',
        name: 'Alžírsko',
        dialCode: '213',
    },
    {
        code: 'AS',
        name: 'Americká Samoa',
        dialCode: '684',
    },
    {
        code: 'VI',
        name: 'Americké Panenské ostrovy',
        dialCode: '1',
    },
    {
        code: 'AD',
        name: 'Andorra',
        dialCode: '376',
    },
    {
        code: 'AO',
        name: 'Angola',
        dialCode: '244',
    },
    {
        code: 'AI',
        name: 'Anguilla',
        dialCode: '1',
    },
    {
        code: 'AQ',
        name: 'Antarktida',
        dialCode: '672',
    },
    {
        code: 'AG',
        name: 'Antigua a Barbuda',
        dialCode: '1',
    },
    {
        code: 'AR',
        name: 'Argentina',
        dialCode: '54',
    },
    {
        code: 'AM',
        name: 'Arménie',
        dialCode: '374',
    },
    {
        code: 'AW',
        name: 'Aruba',
        dialCode: '297',
    },
    {
        code: 'AU',
        name: 'Austrálie',
        dialCode: '61',
    },
    {
        code: 'AZ',
        name: 'Ázerbájdžán',
        dialCode: '994',
    },
    {
        code: 'BS',
        name: 'Bahamy',
        dialCode: '1',
    },
    {
        code: 'BH',
        name: 'Bahrajn',
        dialCode: '973',
    },
    {
        code: 'BD',
        name: 'Bangladéš',
        dialCode: '880',
    },
    {
        code: 'BB',
        name: 'Barbados',
        dialCode: '1',
    },
    {
        code: 'BU',
        name: 'Barma',
        dialCode: '95',
    },
    {
        code: 'BE',
        name: 'Belgie',
        dialCode: '32',
    },
    {
        code: 'BZ',
        name: 'Belize',
        dialCode: '501',
    },
    {
        code: 'BY',
        name: 'Bělorusko',
        dialCode: '375',
    },
    {
        code: 'BJ',
        name: 'Benin',
        dialCode: '229',
    },
    {
        code: 'BM',
        name: 'Bermudy',
        dialCode: '1',
    },
    {
        code: 'BT',
        name: 'Bhútán',
        dialCode: '975',
    },
    {
        code: 'BO',
        name: 'Bolívie',
        dialCode: '591',
    },
    {
        code: 'BQ',
        name: 'Bonaire',
        dialCode: '599',
    },
    {
        code: 'BA',
        name: 'Bosna a Hercegovina',
        dialCode: '387',
    },
    {
        code: 'BW',
        name: 'Botswana',
        dialCode: '267',
    },
    {
        code: 'BV',
        name: 'Bouvetův ostrov',
        dialCode: '55',
    },
    {
        code: 'BR',
        name: 'Brazílie',
        dialCode: '55',
    },
    {
        code: 'IO',
        name: 'Britské indickooceánské území',
        dialCode: '246',
    },
    {
        code: 'VG',
        name: 'Britské Panenské ostrovy',
        dialCode: '1',
    },
    {
        code: 'BN',
        name: 'Brunej',
        dialCode: '673',
    },
    {
        code: 'BG',
        name: 'Bulharsko',
        dialCode: '359',
    },
    {
        code: 'BF',
        name: 'Burkina Faso',
        dialCode: '226',
    },
    {
        code: 'BI',
        name: 'Burundi',
        dialCode: '257',
    },
    {
        code: 'CL',
        name: 'Chile',
        dialCode: '56',
    },
    {
        code: 'HR',
        name: 'Chorvatsko',
        dialCode: '385',
    },
    {
        code: 'CK',
        name: 'Cookovy ostrovy',
        dialCode: '682',
    },
    {
        code: 'CW',
        name: 'Curaçao',
        dialCode: '599',
    },
    {
        code: 'TD',
        name: 'Čad',
        dialCode: '235',
    },
    {
        code: 'ME',
        name: 'Černá Hora',
        dialCode: '382',
    },
    {
        code: 'CN',
        name: 'Čína',
        dialCode: '86',
    },
    {
        code: 'DK',
        name: 'Dánsko',
        dialCode: '45',
    },
    {
        code: 'DM',
        name: 'Dominika',
        dialCode: '1',
    },
    {
        code: 'DO',
        name: 'Dominikánská republika',
        dialCode: '1',
    },
    {
        code: 'DJ',
        name: 'Džibutsko',
        dialCode: '253',
    },
    {
        code: 'EG',
        name: 'Egypt',
        dialCode: '20',
    },
    {
        code: 'EC',
        name: 'Ekvádor',
        dialCode: '593',
    },
    {
        code: 'ER',
        name: 'Eritrea',
        dialCode: '291',
    },
    {
        code: 'EE',
        name: 'Estonsko',
        dialCode: '372',
    },
    {
        code: 'ET',
        name: 'Etiopie',
        dialCode: '251',
    },
    {
        code: 'FO',
        name: 'Faerské ostrovy',
        dialCode: '298',
    },
    {
        code: 'FK',
        name: 'Falklandy',
        dialCode: '500',
    },
    {
        code: 'FJ',
        name: 'Fidži',
        dialCode: '679',
    },
    {
        code: 'PH',
        name: 'Filipíny',
        dialCode: '63',
    },
    {
        code: 'FI',
        name: 'Finsko',
        dialCode: '358',
    },
    {
        code: 'FR',
        name: 'Francie',
        dialCode: '33',
    },
    {
        code: 'GF',
        name: 'Francouzská Guyana',
        dialCode: '594',
    },
    {
        code: 'TF',
        name: 'Francouzská jižní území',
        dialCode: '262',
    },
    {
        code: 'PF',
        name: 'Francouzská Polynésie',
        dialCode: '689',
    },
    {
        code: 'GA',
        name: 'Gabon',
        dialCode: '241',
    },
    {
        code: 'GM',
        name: 'Gambie',
        dialCode: '220',
    },
    {
        code: 'GH',
        name: 'Ghana',
        dialCode: '233',
    },
    {
        code: 'GI',
        name: 'Gibraltar',
        dialCode: '350',
    },
    {
        code: 'GD',
        name: 'Grenada',
        dialCode: '1',
    },
    {
        code: 'GL',
        name: 'Grónsko',
        dialCode: '299',
    },
    {
        code: 'GE',
        name: 'Gruzie',
        dialCode: '995',
    },
    {
        code: 'GP',
        name: 'Guadeloupe',
        dialCode: '590',
    },
    {
        code: 'GU',
        name: 'Guam',
        dialCode: '1',
    },
    {
        code: 'GT',
        name: 'Guatemala',
        dialCode: '502',
    },
    {
        code: 'GG',
        name: 'Guernsey',
        dialCode: '44',
    },
    {
        code: 'GN',
        name: 'Guinea',
        dialCode: '224',
    },
    {
        code: 'GW',
        name: 'Guinea-Bissau',
        dialCode: '245',
    },
    {
        code: 'GY',
        name: 'Guyana',
        dialCode: '592',
    },
    {
        code: 'HT',
        name: 'Haiti',
        dialCode: '509',
    },
    {
        code: 'HM',
        name: 'Heardův ostrov a McDonaldovy ostrovy',
        dialCode: '672',
    },
    {
        code: 'HN',
        name: 'Honduras',
        dialCode: '504',
    },
    {
        code: 'HK',
        name: 'Hongkong',
        dialCode: '852',
    },
    {
        code: 'IN',
        name: 'Indie',
        dialCode: '91',
    },
    {
        code: 'ID',
        name: 'Indonésie',
        dialCode: '62',
    },
    {
        code: 'IQ',
        name: 'Irák',
        dialCode: '964',
    },
    {
        code: 'IR',
        name: 'Írán',
        dialCode: '98',
    },
    {
        code: 'IE',
        name: 'Irsko',
        dialCode: '353',
    },
    {
        code: 'IS',
        name: 'Island',
        dialCode: '354',
    },
    {
        code: 'IT',
        name: 'Itálie',
        dialCode: '39',
    },
    {
        code: 'IL',
        name: 'Izrael',
        dialCode: '972',
    },
    {
        code: 'JM',
        name: 'Jamajka',
        dialCode: '1',
    },
    {
        code: 'JP',
        name: 'Japonsko',
        dialCode: '81',
    },
    {
        code: 'YE',
        name: 'Jemen',
        dialCode: '967',
    },
    {
        code: 'JE',
        name: 'Jersey',
        dialCode: '44',
    },
    {
        code: 'ZA',
        name: 'Jihoafrická republika',
        dialCode: '27',
    },
    {
        code: 'GS',
        name: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
        dialCode: '500',
    },
    {
        code: 'KR',
        name: 'Jižní Korea',
        dialCode: '82',
    },
    {
        code: 'SS',
        name: 'Jižní Súdán',
        dialCode: '211',
    },
    {
        code: 'JO',
        name: 'Jordánsko',
        dialCode: '962',
    },
    {
        code: 'YU',
        name: 'Jugoslávie',
        dialCode: '38',
    },
    {
        code: 'KY',
        name: 'Kajmanské ostrovy',
        dialCode: '1',
    },
    {
        code: 'KH',
        name: 'Kambodža',
        dialCode: '855',
    },
    {
        code: 'CM',
        name: 'Kamerun',
        dialCode: '237',
    },
    {
        code: 'CA',
        name: 'Kanada',
        dialCode: '1',
    },
    {
        code: 'IC',
        name: 'Kanárské ostrovy',
        dialCode: '34',
    },
    {
        code: 'CV',
        name: 'Kapverdy',
        dialCode: '238',
    },
    {
        code: 'QA',
        name: 'Katar',
        dialCode: '974',
    },
    {
        code: 'KZ',
        name: 'Kazachstán',
        dialCode: '7',
    },
    {
        code: 'KE',
        name: 'Keňa',
        dialCode: '254',
    },
    {
        code: 'KI',
        name: 'Kiribati',
        dialCode: '686',
    },
    {
        code: 'CC',
        name: 'Kokosové ostrovy',
        dialCode: '891',
    },
    {
        code: 'CO',
        name: 'Kolumbie',
        dialCode: '57',
    },
    {
        code: 'KM',
        name: 'Komory',
        dialCode: '269',
    },
    {
        code: 'CG',
        name: 'Kongo',
        dialCode: '242',
    },
    {
        code: 'CD',
        name: 'Kongo, demokratická republika',
        dialCode: '243',
    },
    {
        code: 'CR',
        name: 'Kostarika',
        dialCode: '506',
    },
    {
        code: 'CU',
        name: 'Kuba',
        dialCode: '53',
    },
    {
        code: 'KW',
        name: 'Kuvajt',
        dialCode: '965',
    },
    {
        code: 'CY',
        name: 'Kypr',
        dialCode: '357',
    },
    {
        code: 'KG',
        name: 'Kyrgyzstán',
        dialCode: '996',
    },
    {
        code: 'LA',
        name: 'Laos',
        dialCode: '856',
    },
    {
        code: 'LS',
        name: 'Lesotho',
        dialCode: '266',
    },
    {
        code: 'LB',
        name: 'Libanon',
        dialCode: '961',
    },
    {
        code: 'LR',
        name: 'Libérie',
        dialCode: '231',
    },
    {
        code: 'LY',
        name: 'Libye',
        dialCode: '218',
    },
    {
        code: 'LI',
        name: 'Lichtenštejnsko',
        dialCode: '423',
    },
    {
        code: 'LT',
        name: 'Litva',
        dialCode: '370',
    },
    {
        code: 'LV',
        name: 'Lotyšsko',
        dialCode: '371',
    },
    {
        code: 'LU',
        name: 'Lucembursko',
        dialCode: '352',
    },
    {
        code: 'MO',
        name: 'Macao',
        dialCode: '853',
    },
    {
        code: 'MG',
        name: 'Madagaskar',
        dialCode: '261',
    },
    {
        code: 'HU',
        name: 'Maďarsko',
        dialCode: '36',
    },
    {
        code: 'MK',
        name: 'Makedonie',
        dialCode: '389',
    },
    {
        code: 'MY',
        name: 'Malajsie',
        dialCode: '60',
    },
    {
        code: 'MW',
        name: 'Malawi',
        dialCode: '265',
    },
    {
        code: 'MV',
        name: 'Maledivy',
        dialCode: '960',
    },
    {
        code: 'ML',
        name: 'Mali',
        dialCode: '223',
    },
    {
        code: 'MT',
        name: 'Malta',
        dialCode: '356',
    },
    {
        code: 'MA',
        name: 'Maroko',
        dialCode: '212',
    },
    {
        code: 'MH',
        name: 'Marshallovy ostrovy',
        dialCode: '692',
    },
    {
        code: 'MQ',
        name: 'Martinik',
        dialCode: '596',
    },
    {
        code: 'MU',
        name: 'Mauricius',
        dialCode: '230',
    },
    {
        code: 'MR',
        name: 'Mauritánie',
        dialCode: '222',
    },
    {
        code: 'YT',
        name: 'Mayotte',
        dialCode: '262',
    },
    {
        code: 'UM',
        name: 'Menší odlehlé ostrovy Spojených států amerických',
        dialCode: '246',
    },
    {
        code: 'MX',
        name: 'Mexiko',
        dialCode: '52',
    },
    {
        code: 'FM',
        name: 'Mikronésie',
        dialCode: '691',
    },
    {
        code: 'MD',
        name: 'Moldavsko',
        dialCode: '373',
    },
    {
        code: 'MC',
        name: 'Monako',
        dialCode: '377',
    },
    {
        code: 'MN',
        name: 'Mongolsko',
        dialCode: '976',
    },
    {
        code: 'MS',
        name: 'Montserrat',
        dialCode: '1',
    },
    {
        code: 'MZ',
        name: 'Mosambik',
        dialCode: '258',
    },
    {
        code: 'MM',
        name: 'Myanmar',
        dialCode: '95',
    },
    {
        code: 'NA',
        name: 'Namibie',
        dialCode: '264',
    },
    {
        code: 'NR',
        name: 'Nauru',
        dialCode: '674',
    },
    {
        code: 'DE',
        name: 'Německo',
        dialCode: '49',
    },
    {
        code: 'NP',
        name: 'Nepál',
        dialCode: '977',
    },
    {
        code: 'NE',
        name: 'Niger',
        dialCode: '227',
    },
    {
        code: 'NG',
        name: 'Nigérie',
        dialCode: '234',
    },
    {
        code: 'NI',
        name: 'Nikaragua',
        dialCode: '505',
    },
    {
        code: 'NU',
        name: 'Niue',
        dialCode: '683',
    },
    {
        code: 'AN',
        name: 'Nizozemské Antily',
        dialCode: '599',
    },
    {
        code: 'NL',
        name: 'Nizozemsko',
        dialCode: '31',
    },
    {
        code: 'NF',
        name: 'Norfolk',
        dialCode: '672',
    },
    {
        code: 'NO',
        name: 'Norsko',
        dialCode: '47',
    },
    {
        code: 'NC',
        name: 'Nová Kaledonie',
        dialCode: '687',
    },
    {
        code: 'NZ',
        name: 'Nový Zéland',
        dialCode: '64',
    },
    {
        code: 'OM',
        name: 'Omán',
        dialCode: '968',
    },
    {
        code: 'IM',
        name: 'Ostrov Man',
        dialCode: '44',
    },
    {
        code: 'PK',
        name: 'Pákistán',
        dialCode: '92',
    },
    {
        code: 'PW',
        name: 'Palau',
        dialCode: '680',
    },
    {
        code: 'PS',
        name: 'Palestina',
        dialCode: '970',
    },
    {
        code: 'PA',
        name: 'Panama',
        dialCode: '507',
    },
    {
        code: 'PG',
        name: 'Papua Nová Guinea',
        dialCode: '675',
    },
    {
        code: 'PY',
        name: 'Paraguay',
        dialCode: '595',
    },
    {
        code: 'PE',
        name: 'Peru',
        dialCode: '51',
    },
    {
        code: 'PN',
        name: 'Pitcairnovy ostrovy',
        dialCode: '64',
    },
    {
        code: 'CI',
        name: 'Pobřeží slonoviny',
        dialCode: '225',
    },
    {
        code: 'PL',
        name: 'Polsko',
        dialCode: '48',
    },
    {
        code: 'PR',
        name: 'Portoriko',
        dialCode: '1',
    },
    {
        code: 'PT',
        name: 'Portugalsko',
        dialCode: '351',
    },
    {
        code: 'AT',
        name: 'Rakousko',
        dialCode: '43',
    },
    {
        code: 'RE',
        name: 'Réunion',
        dialCode: '262',
    },
    {
        code: 'GQ',
        name: 'Rovníková Guinea',
        dialCode: '240',
    },
    {
        code: 'RO',
        name: 'Rumunsko',
        dialCode: '40',
    },
    {
        code: 'RU',
        name: 'Rusko',
        dialCode: '7',
    },
    {
        code: 'RW',
        name: 'Rwanda',
        dialCode: '250',
    },
    {
        code: 'GR',
        name: 'Řecko',
        dialCode: '30',
    },
    {
        code: 'PM',
        name: 'Saint Pierre a Miquelon',
        dialCode: '508',
    },
    {
        code: 'SV',
        name: 'Salvador',
        dialCode: '503',
    },
    {
        code: 'WS',
        name: 'Samoa',
        dialCode: '685',
    },
    {
        code: 'SM',
        name: 'San Marino',
        dialCode: '378',
    },
    {
        code: 'SA',
        name: 'Saúdská Arábie',
        dialCode: '966',
    },
    {
        code: 'SN',
        name: 'Senegal',
        dialCode: '221',
    },
    {
        code: 'KP',
        name: 'Severní Korea',
        dialCode: '850',
    },
    {
        code: 'MP',
        name: 'Severní Mariany',
        dialCode: '1',
    },
    {
        code: 'SC',
        name: 'Seychely',
        dialCode: '248',
    },
    {
        code: 'SL',
        name: 'Sierra Leone',
        dialCode: '232',
    },
    {
        code: 'SG',
        name: 'Singapur',
        dialCode: '65',
    },
    {
        code: 'SI',
        name: 'Slovinsko',
        dialCode: '386',
    },
    {
        code: 'SO',
        name: 'Somálsko',
        dialCode: '252',
    },
    {
        code: 'AE',
        name: 'Spojené arabské emiráty',
        dialCode: '971',
    },
    {
        code: 'US',
        name: 'Spojené státy',
        dialCode: '1',
    },
    {
        code: 'RS',
        name: 'Srbsko',
        dialCode: '381',
    },
    {
        code: 'CS',
        name: 'Srbsko a Černá Hora',
        dialCode: '381',
    },
    {
        code: 'LK',
        name: 'Srí Lanka',
        dialCode: '94',
    },
    {
        code: 'CF',
        name: 'Středoafrická republika',
        dialCode: '236',
    },
    {
        code: 'SD',
        name: 'Súdán',
        dialCode: '249',
    },
    {
        code: 'SR',
        name: 'Surinam',
        dialCode: '597',
    },
    {
        code: 'SH',
        name: 'Svatá Helena',
        dialCode: '290',
    },
    {
        code: 'LC',
        name: 'Svatá Lucie',
        dialCode: '1',
    },
    {
        code: 'BL',
        name: 'Svatý Bartoloměj',
        dialCode: '590',
    },
    {
        code: 'KN',
        name: 'Svatý Kryštof a Nevis',
        dialCode: '1',
    },
    {
        code: 'MF',
        name: 'Svatý Martin',
        dialCode: '590',
    },
    {
        code: 'ST',
        name: 'Svatý Tomáš a Princův ostrov',
        dialCode: '239',
    },
    {
        code: 'VC',
        name: 'Svatý Vincenc a Grenadiny',
        dialCode: '1',
    },
    {
        code: 'SZ',
        name: 'Svazijsko',
        dialCode: '268',
    },
    {
        code: 'SY',
        name: 'Sýrie',
        dialCode: '963',
    },
    {
        code: 'SB',
        name: 'Šalamounovy ostrovy',
        dialCode: '677',
    },
    {
        code: 'ES',
        name: 'Španělsko',
        dialCode: '34',
    },
    {
        code: 'SJ',
        name: 'Špicberky a Jan Mayen',
        dialCode: '47',
    },
    {
        code: 'SE',
        name: 'Švédsko',
        dialCode: '46',
    },
    {
        code: 'CH',
        name: 'Švýcarsko',
        dialCode: '41',
    },
    {
        code: 'TJ',
        name: 'Tádžikistán',
        dialCode: '992',
    },
    {
        code: 'TZ',
        name: 'Tanzanie',
        dialCode: '255',
    },
    {
        code: 'TW',
        name: 'Tchaj-wan',
        dialCode: '886',
    },
    {
        code: 'TH',
        name: 'Thajsko',
        dialCode: '66',
    },
    {
        code: 'TG',
        name: 'Togo',
        dialCode: '228',
    },
    {
        code: 'TK',
        name: 'Tokelau',
        dialCode: '690',
    },
    {
        code: 'TO',
        name: 'Tonga',
        dialCode: '676',
    },
    {
        code: 'TT',
        name: 'Trinidad a Tobago',
        dialCode: '1',
    },
    {
        code: 'TN',
        name: 'Tunisko',
        dialCode: '216',
    },
    {
        code: 'TR',
        name: 'Turecko',
        dialCode: '90',
    },
    {
        code: 'TM',
        name: 'Turkmenistán',
        dialCode: '993',
    },
    {
        code: 'TC',
        name: 'Turks a Caicos',
        dialCode: '1',
    },
    {
        code: 'TV',
        name: 'Tuvalu',
        dialCode: '688',
    },
    {
        code: 'UG',
        name: 'Uganda',
        dialCode: '256',
    },
    {
        code: 'UA',
        name: 'Ukrajina',
        dialCode: '380',
    },
    {
        code: 'UY',
        name: 'Uruguay',
        dialCode: '598',
    },
    {
        code: 'UZ',
        name: 'Uzbekistán',
        dialCode: '998',
    },
    {
        code: 'CX',
        name: 'Vánoční ostrov',
        dialCode: '61',
    },
    {
        code: 'VU',
        name: 'Vanuatu',
        dialCode: '678',
    },
    {
        code: 'VA',
        name: 'Vatikán',
        dialCode: '379',
    },
    {
        code: 'GB',
        name: 'Velká Británie',
        dialCode: '44',
    },
    {
        code: 'VE',
        name: 'Venezuela',
        dialCode: '58',
    },
    {
        code: 'VN',
        name: 'Vietnam',
        dialCode: '84',
    },
    {
        code: 'TL',
        name: 'Východní Timor',
        dialCode: '670',
    },
    {
        code: 'WF',
        name: 'Wallis a Futuna',
        dialCode: '681',
    },
    {
        code: 'ZM',
        name: 'Zambie',
        dialCode: '260',
    },
    {
        code: 'EH',
        name: 'Západní Sahara',
        dialCode: '212',
    },
    {
        code: 'ZW',
        name: 'Zimbabwe',
        dialCode: '263',
    },
];
