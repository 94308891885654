import { Base64 } from './base64';
import { CPDate } from '@cp-shared/common-utilities';
import { Insurance, ProductTypeLevel3 } from './contracts';
import { MyProfileData } from './my-profile';

export type Document = {
    contractNumber: string;
    documentType: DocumentType.COPY_OF_REGISTRATION_CERTIFICATE;
    _downloadLink: string;
};

export type Documents = {
    copyOfRegistrationCertificates: Document[];
};

export enum DocumentType {
    GENERAL_TERMS = 'GENERAL_TERMS',
    GREEN_CARD = 'GREEN_CARD',
    GREEN_CARD_UPCOMING = 'GREEN_CARD_UPCOMING',
    DRIVER_SET = 'DRIVER_SET',
    COPY_OF_REGISTRATION_CERTIFICATE = 'COPY_OF_REGISTRATION_CERTIFICATE',
}

export type DocumentNode = {
    data: Base64;
};

export type DocumentError = 'NOT_FOUND' | 'FILE_CONTENT_NOT_EXIST';

export enum GreenCardPdfTemplate {
    ALLIANZ_BRANDED = 'Allianz_branded',
    ALLIANZ_NON_BRANDED = 'Allianz_non_branded',
    GCP_BRANDED = 'GCP_branded',
    GCP_NON_BRANDED = 'GCP_non_branded',
    UNIQA = 'UNIQA',
    NONE = 'NONE',
}

export type GreenCardData = {
    dateFrom?: CPDate;
    dateTo?: CPDate;
    insurance?: Insurance;
    registrationNumber?: string;
    vehicleCategoryCode?: string;
    vehicleMake?: string;
    brand?: string;
    productTypeLevel3?: ProductTypeLevel3;
    profileData?: MyProfileData;
};
