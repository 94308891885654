import React, { useState } from 'react';
import {
    AddressChangeRequest,
    Addresses,
    AddressType,
    CustomerType,
    EditStatus,
    getChangeAddressEndpoint,
} from '@cp-cz/common';
import { MainAddress } from './main-address/MainAddress';
import { ContactAddress } from './contact-address/ContactAddress';
import { useTranslation } from 'react-i18next';
import { DataOverview, Spinner } from '@cp-shared/frontend-ui';
import { isEmpty } from 'lodash';
import { Base64File } from '@cp-shared/apis';
import { CpDataApi } from '../../../cp-xhr';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AddressChangeForm } from './common/edit-view/getSavedData';
import { getBase64 } from 'components/file-upload/utils';

export const AddressesSection: React.FC<{ addresses?: Addresses; customerType?: CustomerType }> = ({
    addresses,
    customerType,
}) => {
    const { t } = useTranslation('my-profile');
    const [lastMainAddressEditStatus, setMainAddressLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [lastContAddressEditStatus, setContAddressLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { path } = useRouteMatch();
    const history = useHistory();

    const isEmptyMainAddress = isEmpty(addresses?.mainAddress);
    const isEmptyContactAddress =
        isEmpty(addresses?.contactAddress?.country) &&
        isEmpty(addresses?.contactAddress?.city) &&
        isEmpty(addresses?.contactAddress?.street) &&
        isEmpty(addresses?.contactAddress?.zipCode);

    if (!addresses || !customerType || (isEmptyMainAddress && isEmptyContactAddress)) {
        return null;
    }

    const backToNonEditMode = (): void => {
        history.push(path);
    };

    const handleSubmit =
        (addressType: AddressType) =>
        async (values: AddressChangeForm): Promise<void> => {
            setIsSubmitting(true);
            let files;
            if (values?.files) {
                const filePromises: Promise<Base64File>[] = values.files.map((file) => getBase64(file));
                files = await Promise.all(filePromises);
            }

            const body: AddressChangeRequest = {
                customerType: customerType,
                addressType: addressType,
                city: values.city,
                country: values.country,
                street: values.street,
                streetNumber: values.streetNumber,
                zipCode: values.zipCode,
                ...(files && { files }),
            };

            CpDataApi.post(getChangeAddressEndpoint(), body)
                .then(() => {
                    addressType === AddressType.MAIN
                        ? setMainAddressLastEditStatus(EditStatus.SUCCESS)
                        : setContAddressLastEditStatus(EditStatus.SUCCESS);
                })
                .catch(() => {
                    addressType === AddressType.MAIN
                        ? setMainAddressLastEditStatus(EditStatus.ERROR)
                        : setContAddressLastEditStatus(EditStatus.ERROR);
                })
                .finally(() => {
                    backToNonEditMode();
                    setIsSubmitting(false);
                });
        };

    return (
        <DataOverview title={t('addresses-section.title')} className={'u-pr-none u-pb-none'}>
            {isSubmitting && <Spinner fullPage />}
            <MainAddress
                addresses={!isEmptyMainAddress ? addresses : undefined}
                customerType={customerType}
                handleSubmit={handleSubmit(AddressType.MAIN)}
                backToNonEditMode={backToNonEditMode}
                setLastEditStatus={setMainAddressLastEditStatus}
                lastEditStatus={lastMainAddressEditStatus}
            />
            <ContactAddress
                addresses={{
                    mainAddress: addresses.mainAddress,
                    contactAddress: !isEmptyContactAddress ? addresses.contactAddress : addresses.mainAddress,
                }}
                customerType={customerType}
                handleSubmit={handleSubmit(AddressType.CONTACT)}
                backToNonEditMode={backToNonEditMode}
                lastEditStatus={lastContAddressEditStatus}
                setLastEditStatus={setContAddressLastEditStatus}
            />
        </DataOverview>
    );
};
