import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-its/bronson-react';
import { useConsentHookpage } from './useConsentHookpage';
import { ConsentHookpageComponentUi } from './ui/ConsentHookpageComponentUi';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { Spinner, useAuthentication } from '@cp-shared/frontend-ui';
import { useHistory } from 'react-router-dom';
import { acceptedConsentDateStorageKey, consentValidDate } from '../../../config';
import { hookPageRedirectionPath, landingPagePath } from '../../navigation/paths';
import { formatCpDate } from '@cp-shared/common-utilities';
import moment from 'moment-timezone';
import { getHashedUserId } from '../utils';

export const ConsentHookpageComponent: React.FC = () => {
    const { t } = useTranslation('terms-and-conditions-hookpage');
    const auth = useAuthentication();
    const history = useHistory();

    const { cmsContent: consentHookpageContent, isLoading, loadingError } = useConsentHookpage();

    const ConsentHookpageComponentWithHandlers = withLoadingAndNoConnectionHandler(ConsentHookpageComponentUi);

    const handleAcceptConsent = () => {
        const hashedUserId = getHashedUserId(auth);
        window.localStorage.setItem(acceptedConsentDateStorageKey + hashedUserId, consentValidDate);
        window.location.assign(hookPageRedirectionPath());
    };

    const handleRejectConsent = () => {
        if (moment(formatCpDate().format('YYYY-MM-DD')).isSameOrAfter(consentValidDate)) {
            auth.logout({ redirectUri: window.location.origin + landingPagePath() });
        } else {
            history.push(hookPageRedirectionPath());
        }
    };

    return (
        <>
            <ConsentHookpageComponentWithHandlers
                isLoading={isLoading}
                handleAcceptConsent={handleAcceptConsent}
                handleRejectConsent={handleRejectConsent}
                consentHookpageContent={consentHookpageContent}
                hasError={!!loadingError}
                loadingPlaceholder={<Spinner center />}
            />
            {loadingError && (
                <ButtonContainer center className={'u-mt'}>
                    <Button secondary onClick={() => handleRejectConsent()} testId="error-reject-button">
                        {t('button.reject')}
                    </Button>
                </ButtonContainer>
            )}
        </>
    );
};
