import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationStatus } from '@cp-shared/frontend-ui';

export type NoConnectionNotificationProps = {
    testId?: string;
};

export const NoConnectionNotification: React.FC<NoConnectionNotificationProps> = ({ testId }) => {
    const { t } = useTranslation('notification');
    return <Notification status={NotificationStatus.error} text={t('no-connection-notification')} testId={testId} />;
};
