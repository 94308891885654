import { ContactDetails, countryCodes, EditStatus, getContactDetailsEndpoint } from '@cp-cz/common';
import { changeContactDetailsPath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared/frontend-ui';

export const ContactSection: React.FC<{ contactDetails?: ContactDetails }> = ({ contactDetails }) => {
    const initialValues: ContactDetails = {
        email: contactDetails?.email || '',
        phoneNumber: contactDetails?.phoneNumber || '',
        countryPrefixDigits: contactDetails?.countryPrefixDigits || '',
    };

    const { path } = useRouteMatch();
    const history = useHistory();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileContactSubmitFailed',
        confirmSuccess: 'onEditProfileContactSuccess',
    });

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeContactDetailsPath());
    };

    const backToNonEditMode = (): void => {
        history.push(path);
    };

    const handleSubmit = (contactDetails: ContactDetails): void => {
        let countryPrefixDigits = '';

        if (contactDetails?.countryPrefixDigits) {
            countryPrefixDigits =
                contactDetails?.countryPrefixDigits[0] !== '+'
                    ? `+${countryCodes.find((x) => x.code === contactDetails.countryPrefixDigits)?.dialCode}`
                    : contactDetails.countryPrefixDigits;
        }

        const body = {
            ...contactDetails,
            countryPrefixDigits,
        };

        setIsSubmitting(true);
        CpDataApi.post(getContactDetailsEndpoint(), body)
            .then(() => {
                onSuccess();
                setLastEditStatus(EditStatus.SUCCESS);
            })
            .catch(() => {
                onError();
                setLastEditStatus(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
                backToNonEditMode();
            });
    };

    return (
        <Switch>
            <Route exact path={changeContactDetailsPath()}>
                <EditView
                    initialValues={initialValues}
                    handleSubmit={handleSubmit}
                    onCancel={(): void => {
                        onCancel('Contact');
                        backToNonEditMode();
                    }}
                    lastEditStatus={lastEditStatus}
                    isSubmitting={isSubmitting}
                />
            </Route>
            <Route path={path}>
                <ConsultView
                    contactDetails={contactDetails}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                />
            </Route>
        </Switch>
    );
};
