import { Identification } from '@cp-cz/common';

export const NaturalPersonIdentificationWithAllValues: Identification = {
    customerId: '1111',
    type: 'NATURAL_PERSON',
    segmentation: 'FLT',
    data: {
        academicTitle: 'Prof.',
        firstName: 'John',
        surname: 'Doe',
    },
};

export const NaturalPersonWithLegalIdentificationWithAllValues: Identification = {
    customerId: '1111',
    type: 'NATURAL_PERSON_WITH_LEGAL_PERSONALITY',
    segmentation: 'RET',
    data: {
        companyId: '123456',
        vatIdNumber: '123456',
        firstName: 'John',
        surname: 'Doe',
    },
};

export const LegalPersonIdentificationWithAllValues: Identification = {
    customerId: '1111',
    type: 'LEGAL_PERSON',
    segmentation: 'FLT',
    data: {
        companyId: '123456',
        vatIdNumber: '123456',
        companyName: 'John Doe Company',
    },
};

export const NaturalPersonIdentificationWithMissinglValues: Identification = {
    customerId: '1111',
    type: 'NATURAL_PERSON',
    data: {
        surname: 'Doe',
    },
};

export const NaturalPersonWithLegalIdentificationWithMissingValues: Identification = {
    customerId: '1111',
    type: 'NATURAL_PERSON_WITH_LEGAL_PERSONALITY',
    data: {
        companyId: '123456',
        vatIdNumber: '123456',
    },
};

export const LegalPersonIdentificationWithMissingValues: Identification = {
    customerId: '1111',
    type: 'LEGAL_PERSON',
    data: {
        companyName: 'John Doe Company',
    },
};

export const NaturalPersonIdentificationWithoutValues: Identification = {
    customerId: '',
    type: undefined,
    segmentation: '',
    data: {
        academicTitle: '.',
        firstName: '',
        surname: '',
    },
};
