import { Contract, ProductTypeLevel3 } from '@cp-cz/common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared/frontend-ui';
import React from 'react';
import { TranslationFormat, useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';

export const ContractSummaryItems: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { t, f } = useTranslationWithFormatting('contracts');
    const { isActive, productTypeLevel3 } = contract;
    const {
        residualValue,
        nextDueDate,
        nextDueAmount,
        outstandingBalance,
        currencyCode,
        vehicleReturnDate,
        startDate,
        endDate,
    } = contract.details.financial || {};

    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;
    const noValuePlaceholder = '-';

    const getOutstandingBalanceItem = (): ContractSummaryItemProps => {
        const suffix = !outstandingBalance
            ? 'equal-zero'
            : outstandingBalance < 0
            ? 'less-than-zero'
            : 'more-than-zero';

        return {
            label: t(`outstanding-balance.${suffix}`),
            testClass: 'outstanding-balance',
            value:
                outstandingBalance || outstandingBalance === 0
                    ? f(Math.abs(outstandingBalance), TranslationFormat.CURRENCY, currencyCodeTranslation)
                    : noValuePlaceholder,
            tooltip: outstandingBalance ? t('outstanding-balance.tooltip') : undefined,
        };
    };

    const getActiveContractSummaryItems = (): ContractSummaryItemProps[] => {
        const nextDueAmountAndDateItems = [
            {
                label: t('next-due-amount'),
                testClass: 'next-due-amount',
                value:
                    nextDueAmount || nextDueAmount === 0
                        ? f(nextDueAmount, TranslationFormat.CURRENCY, currencyCodeTranslation)
                        : noValuePlaceholder,
            },
            {
                label: t('next-due-date'),
                testClass: 'next-due-date',
                value: nextDueDate ? f(nextDueDate, TranslationFormat.DATE) : noValuePlaceholder,
            },
        ];
        const outstandingBalanceItem =
            productTypeLevel3 === ProductTypeLevel3.ICVBWO || productTypeLevel3 === ProductTypeLevel3.ICVBW
                ? [getOutstandingBalanceItem()]
                : [];

        const vehicleReturnDateItem =
            productTypeLevel3 === ProductTypeLevel3.OL
                ? [
                      {
                          label: t('vehicle-return-date'),
                          testClass: 'vehicle-return-date',
                          value: vehicleReturnDate ? f(vehicleReturnDate, TranslationFormat.DATE) : noValuePlaceholder,
                      },
                  ]
                : [];

        const residualValueItem =
            productTypeLevel3 === ProductTypeLevel3.FL
                ? [
                      {
                          label: t('residual-value'),
                          testClass: 'residual-value',
                          value:
                              residualValue || residualValue === 0
                                  ? f(residualValue, TranslationFormat.CURRENCY, currencyCodeTranslation)
                                  : noValuePlaceholder,
                      },
                  ]
                : [];

        return isActive
            ? [...nextDueAmountAndDateItems, ...outstandingBalanceItem, ...residualValueItem, ...vehicleReturnDateItem]
            : [];
    };

    const getInactiveContractSummaryItems = (): ContractSummaryItemProps[] => {
        return !isActive
            ? [
                  {
                      label: t('start-date'),
                      testClass: 'start-date',
                      value: startDate ? f(startDate, TranslationFormat.DATE) : noValuePlaceholder,
                  },
                  {
                      label: t('end-date'),
                      testClass: 'end-date',
                      value: endDate ? f(endDate, TranslationFormat.DATE) : noValuePlaceholder,
                  },
                  getOutstandingBalanceItem(),
              ]
            : [];
    };

    return <ContractSummary items={[...getActiveContractSummaryItems(), ...getInactiveContractSummaryItems()]} />;
};
