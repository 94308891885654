import { useAnalyticsActionTracker, useAuthentication } from '@cp-shared/frontend-ui';
import { useTranslation } from 'react-i18next';
import { hookPageRedirectionPath } from '../components/navigation/paths';
import { idpHint, isAfterLoginLocalStorageKey } from 'config';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';

export function useLogin(pathFn: () => string = hookPageRedirectionPath, loginHint?: string): () => Promise<void> {
    const { login } = useAuthentication();
    const { i18n } = useTranslation();
    const { pathname } = useLocation();
    const { onAction } = useAnalyticsActionTracker('login');

    const locale = i18n.languages[0];
    const redirectUri = `${window.location.origin}${pathFn()}`;

    return useCallback((): Promise<void> => {
        window.localStorage.setItem(isAfterLoginLocalStorageKey, 'true');
        onAction(pathname);
        return login({
            redirectUri,
            locale,
            loginHint,
            idpHint,
            prompt: 'login',
        });
    }, [pathname, redirectUri, locale, loginHint, login, onAction]);
}
