export function getMyProfileEndpoint(): string {
    return `/my-profile`;
}

export function getContactDetailsEndpoint(): string {
    return `${getMyProfileEndpoint()}/contact-details`;
}

export function getChangeAddressEndpoint(): string {
    return `${getMyProfileEndpoint()}/change-address`;
}

export function getMarketingPreferencesEndpoint(): string {
    return `${getMyProfileEndpoint()}/marketing-preferences`;
}
export function getGreenCardPdfEndpoint(): string {
    return `${getMyProfileEndpoint()}/green-card-pdf`;
}

export function getHashedUserIdEndpoint(): string {
    return '/userId';
}
