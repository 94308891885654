import React from 'react';
import { ThirdPartyLicenses, License } from '@cp-cz/common';
import { PageWrap } from '@vwfs-its/bronson-react';
import {
    groupLicensesByType,
    HeroImage,
    SimpleNavItemProps,
    SiteNav,
    ThirdPartyLicensesTable,
} from '@cp-shared/frontend-ui';
import { useTranslation } from 'react-i18next';

export const ThirdPartyLicensesUi: React.FC<{ thirdPartyLicenses?: ThirdPartyLicenses }> = ({ thirdPartyLicenses }) => {
    const { t } = useTranslation('third-party-licenses');

    if (!thirdPartyLicenses) {
        return null;
    }

    const { teaser, content } = thirdPartyLicenses;
    const columnHeadings: string[] = [t('columns.name'), t('columns.version'), t('columns.copyright')];

    const onHandleWebLicensesClick = (): void => {
        window.open('https://www.vwfs.cz/o-spolecnosti/licence.html#licence_webu', '_blank');
    };

    const onHandleOnlinePackages = (): void => {
        window.open('https://www.vwfs.cz/o-spolecnosti/licence.html#Servisn%C3%AD_bal%C3%AD%C4%8Dky_online', '_blank');
    };

    const navigationLinks: SimpleNavItemProps[] = [
        {
            onClick: onHandleWebLicensesClick,
            label: t('navigation.web-license'),
        },
        {
            onClick: onHandleOnlinePackages,
            label: t('navigation.online-packages'),
        },
        {
            url: 'https://my.vwfs.cz/licenses',
            label: t('navigation.customer-portal'),
        },
    ];
    return (
        <>
            <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} subTitle={teaser.subTitle} shallow={true} />
            <PageWrap size={'medium'}>
                <SiteNav navigationItems={navigationLinks} />
                {Object.entries(groupLicensesByType<License>(content)).map(([licenseType, licenses]) => (
                    <ThirdPartyLicensesTable
                        key={licenseType}
                        columnHeadings={columnHeadings}
                        viewLicenseLabel={''}
                        rows={licenses}
                        link={{ label: '', url: '' }}
                        textAboveTable={licenseType}
                        description={''}
                    />
                ))}
            </PageWrap>
        </>
    );
};
