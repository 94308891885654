import React, { useEffect } from 'react';
import { Button, ButtonContainer, Fieldset, Layout } from '@vwfs-its/bronson-react';
import { CleaveInput, ValidatedInput, ValidatedSelect, ValidatedSelectItem, FileUpload } from '@cp-shared/frontend-ui';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { getStreetName, getStreetNumber } from '../AddressList';
import {
    Addresses,
    CountryCode,
    countryCodes,
    validAddressFileFormats,
    maxAddressFileSize,
    formatAsFileSize,
} from '@cp-cz/common';

type EditViewChangeFormProps = {
    onCancel: () => void;
    shouldPrefillForm: boolean;
    isPOCustomer: boolean;
    addresses: Addresses;
    isMainAddress?: boolean;
};

export const EditViewChangeForm: React.FC<EditViewChangeFormProps> = ({
    onCancel,
    addresses,
    shouldPrefillForm,
    isPOCustomer,
    isMainAddress,
}) => {
    const { t } = useTranslation('my-profile');
    const { setFieldValue, submitForm, resetForm } = useFormikContext();

    const countryNames: ValidatedSelectItem[] = countryCodes.map((countryCode: CountryCode) => ({
        label: countryCode.name,
        value: countryCode.name,
    }));

    useEffect(() => {
        (async () => {
            if (shouldPrefillForm && addresses.mainAddress) {
                await setFieldValue('country', addresses.mainAddress.country || 'Česko');
                await setFieldValue('city', addresses.mainAddress.city);
                await setFieldValue('zipCode', addresses.mainAddress.zipCode);
                await setFieldValue('street', getStreetName(addresses.mainAddress.street));
                await setFieldValue('streetNumber', getStreetNumber(addresses.mainAddress.street));
            }
        })();

        if (!shouldPrefillForm) {
            resetForm();
        }
    }, [shouldPrefillForm, setFieldValue, resetForm, addresses]);

    return (
        <Fieldset>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" m="1/1">
                        <ValidatedSelect
                            name="country"
                            selectItems={countryNames}
                            label={t('addresses-section.edit-view.country')}
                            isMandatory
                            disabled={isMainAddress || shouldPrefillForm}
                            placeholder={''}
                            disablePlaceholder
                            testId={'countrySelect'}
                        />
                    </Layout.Item>
                    <Layout.Item default="1/3" m="1/1">
                        <ValidatedInput
                            label={t('addresses-section.edit-view.city')}
                            name="city"
                            isMandatory
                            disabled={shouldPrefillForm}
                            testId={'cityInput'}
                        />
                    </Layout.Item>
                    <Layout.Item default="1/3" m="1/1">
                        <CleaveInput
                            cleaveOptions={{
                                delimiter: '',
                                blocks: [5],
                                numericOnly: true,
                            }}
                            inputMode={'numeric'}
                            label={t('addresses-section.edit-view.zipCode')}
                            name="zipCode"
                            disabled={shouldPrefillForm}
                            testId="zipCodeInput"
                            isMandatory
                        />
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/2" m="1/1">
                        <ValidatedInput
                            label={t('addresses-section.edit-view.street')}
                            name="street"
                            disabled={shouldPrefillForm}
                            testId={'streetInput'}
                        />
                    </Layout.Item>
                    <Layout.Item default="1/2" m="1/1">
                        <ValidatedInput
                            label={t('addresses-section.edit-view.street-number')}
                            name="streetNumber"
                            isMandatory
                            disabled={shouldPrefillForm}
                            testId={'streetNumberInput'}
                        />
                    </Layout.Item>

                    {isMainAddress && (
                        <Layout.Item>
                            <h5>
                                {isPOCustomer
                                    ? t('addresses-section.edit-view.file-upload.headline-legal')
                                    : t('addresses-section.edit-view.file-upload.headline')}
                            </h5>
                            <p>{t('addresses-section.edit-view.file-upload.text')}</p>
                            <FileUpload
                                name={'files'}
                                descriptionText={t('addresses-section.edit-view.file-upload.upload-title')}
                                descriptionSupplementaryText={t(
                                    'addresses-section.edit-view.file-upload.upload-description',
                                )}
                                buttonText={t('addresses-section.edit-view.file-upload.send-button')}
                                cancelLabel={t('addresses-section.edit-view.file-upload.cancel-button')}
                                fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                maxFileSize={maxAddressFileSize}
                                validFileFormats={validAddressFileFormats}
                                maxFileSizePerFile={maxAddressFileSize}
                                multiple={false}
                                sizeError={t('addresses-section.edit-view.validation-error.max-total-size')}
                                typeError={t('addresses-section.edit-view.validation-error.file-type')}
                            />
                        </Layout.Item>
                    )}
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item>
                        <ButtonContainer center>
                            <Button secondary type="button" onClick={onCancel} testId={'cancel'}>
                                {t('addresses-section.edit-view.cancel-button')}
                            </Button>
                            <Button type="button" onClick={submitForm}>
                                {t('addresses-section.edit-view.submit-button')}
                            </Button>
                        </ButtonContainer>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
        </Fieldset>
    );
};
