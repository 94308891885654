import { Contract } from '@cp-cz/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared/common-utilities';

import { AbstractDataState, useGetSimpleApiData } from '@cp-shared/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';

export function useContracts(): AbstractDataState<Contract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchContracts, ContractsDataSelector);
}
