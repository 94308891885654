import { ContactDetails } from '@cp-cz/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ContactList: React.FC<{ contactDetails?: ContactDetails }> = ({ contactDetails }) => {
    const { t } = useTranslation('my-profile');

    const phoneNumberWithPrefix = `${contactDetails?.countryPrefixDigits || ''}${contactDetails?.phoneNumber || ''}`;

    const contactList: DefinitionListItem[] = [
        {
            label: t('contact-section.consult-view.email'),
            value: contactDetails?.email || '-',
            testId: 'contact-details-email',
        },
        {
            label: t('contact-section.consult-view.phone'),
            value: (contactDetails?.phoneNumber && phoneNumberWithPrefix) || '-',
            testId: 'contact-details-home-phone',
        },
    ];

    return <DefinitionListHorizontal list={contactList} />;
};
