import React from 'react';
import { Insurance } from '@cp-cz/common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

type GeneralSectionProps = {
    insurance: Insurance;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ insurance }) => {
    const { t, f } = useTranslationWithFormatting('insurance-details');
    const translationPrefix = 'general-section';

    const { contractInsuranceNumber, insuranceStartDate, insuranceCompanyName } = insurance;

    if (!contractInsuranceNumber && !insuranceStartDate && !insuranceCompanyName) return null;

    const itemList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.contract-number`),
            value: contractInsuranceNumber || '-',
            testId: 'contract-insurance-number',
        },
        {
            label: t(`${translationPrefix}.start-date`),
            value: insuranceStartDate ? f(insuranceStartDate, TranslationFormat.DATE) : '-',
            testId: 'start-date',
        },
        {
            label: t(`${translationPrefix}.company-name`),
            value: insuranceCompanyName || '-',
            testId: 'company-name',
        },
    ];

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)} withoutCardEffect>
            <DefinitionListHorizontal list={itemList} />
        </DataOverview>
    );
};
