export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractEndpoint(encryptedContractNumber: string): string {
    return `${getContractsEndpoint()}/${encryptedContractNumber}`;
}

export function getPaymentsEndpoint(encryptedContractNumber: string): string {
    return `${getContractsEndpoint()}/${encryptedContractNumber}/payments`;
}

export function getAmortizationTablePdfDownloadEndpoint(encryptedContractNumber: string): string {
    return `${getContractsEndpoint()}/${encryptedContractNumber}/amortization-table/download`;
}
