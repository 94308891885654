import { CPDate, formatCpDate } from '@cp-shared/common-utilities';

export const getRemainingContractDurationDifference = (endDate: CPDate): number => {
    const todayMoment = formatCpDate().toMoment().startOf('day');
    const endMoment = formatCpDate(endDate).toMoment().startOf('day');

    if (todayMoment.isSame(endMoment)) {
        return 0;
    }

    const isPast = endMoment.isBefore(todayMoment);

    const diff = endMoment.diff(todayMoment, 'M');

    if (endMoment.format('DD') === todayMoment.format('DD')) {
        return diff;
    } else {
        return diff + (isPast ? -1 : 0);
    }
};
