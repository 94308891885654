import {
    contactDetailsHookPagePath,
    marketingPreferencesHookPagePath,
    dashboardPagePath,
    hookPageRedirectionPath,
    consentHookPagePath,
} from 'components/navigation/paths';
import {
    acceptedConsentDateStorageKey,
    areContactDetailsChangedStorageKey,
    areContactDetailsConfirmedStorageKey,
    consentValidDate,
    isAfterContactDetailsHookPageStorageKey,
    isAfterMarketingConsentHookPageStorageKey,
    isMarketingConsentSubmittedStorageKey,
} from 'config';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { ContactDetailsHookpageComponent } from './contact-details-hookpage-component/ContactDetailsHookpageComponent';
import { MarketingPreferencesHookpageComponent } from './marketing-preferences-hookpage-component/MarketingPreferencesHookpageComponent';
import { useAuthentication } from '@cp-shared/frontend-ui';
import { getHashedUserId } from './utils';

export const HookPageRedirection: React.FC = () => {
    const history = useHistory();
    const lastLocation = useLastLocation();
    const auth = useAuthentication();
    const [seenContactHookPage, setSeenContactHookPage] = useState(false);
    const [seenMarketingHookPage, setSeenMarketingHookPage] = useState(false);

    const hashedUserId = getHashedUserId(auth);
    const localStorageUserConsentDate = window.localStorage.getItem(acceptedConsentDateStorageKey + hashedUserId);

    const showUserContactDetailsHookPage = !window.localStorage.getItem(isAfterContactDetailsHookPageStorageKey);
    const showUserMarketingConsentHookPage = !window.localStorage.getItem(isAfterMarketingConsentHookPageStorageKey);
    const showUserConsentHookPage =
        !localStorageUserConsentDate || !moment(localStorageUserConsentDate).isSame(consentValidDate);
    const isPreviousPageConsentHookpage = lastLocation?.pathname === consentHookPagePath();
    const handleSetSeenHookpage = (path: string) => {
        if (path === contactDetailsHookPagePath()) {
            setSeenContactHookPage(true);
        } else if (path === marketingPreferencesHookPagePath()) {
            setSeenMarketingHookPage(true);
        }
        history.push(hookPageRedirectionPath());
    };

    useEffect(() => {
        localStorage.setItem(isMarketingConsentSubmittedStorageKey, '');
        localStorage.setItem(areContactDetailsChangedStorageKey, '');
        localStorage.setItem(areContactDetailsConfirmedStorageKey, '');
    }, []);

    useEffect(() => {
        if (
            showUserConsentHookPage &&
            !seenContactHookPage &&
            !seenMarketingHookPage &&
            !isPreviousPageConsentHookpage
        ) {
            history.push(consentHookPagePath());
        } else if (showUserMarketingConsentHookPage && !seenMarketingHookPage) {
            history.push(marketingPreferencesHookPagePath());
        } else if (showUserContactDetailsHookPage && !seenContactHookPage) {
            history.push(contactDetailsHookPagePath());
        } else {
            history.push(dashboardPagePath());
        }
        // eslint-disable-next-line
    }, [
        history,
        seenContactHookPage,
        seenMarketingHookPage,
        showUserContactDetailsHookPage,
        showUserMarketingConsentHookPage,
        showUserConsentHookPage,
    ]);

    return (
        <Switch>
            <Route exact path={contactDetailsHookPagePath()}>
                <ContactDetailsHookpageComponent handleSetSeenHookpage={handleSetSeenHookpage} />
            </Route>
            <Route exact path={marketingPreferencesHookPagePath()}>
                <MarketingPreferencesHookpageComponent handleSetSeenHookpage={handleSetSeenHookpage} />
            </Route>
        </Switch>
    );
};
