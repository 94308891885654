import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { CookiePolicyUi } from './ui';
import { useCookiePolicy } from './useCookiePolicy';
import { useAnalyticsPageViewTracker, StaticPageLoadingPlaceholder } from '@cp-shared/frontend-ui';

export const CookiePolicy: React.FC = () => {
    const { cmsContent: cookiePolicy, isLoading, loadingError } = useCookiePolicy();

    useAnalyticsPageViewTracker('cookiePolicy', !!cookiePolicy);

    const CookiePolicyWithHandlers = withLoadingAndNoConnectionHandler(CookiePolicyUi);

    return (
        <CookiePolicyWithHandlers
            isLoading={isLoading}
            cookiePolicy={cookiePolicy}
            hasError={!!loadingError}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
