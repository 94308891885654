import { Spinner } from '@cp-shared/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile/useMyProfile';
import { Button, ButtonContainer } from '@vwfs-its/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetailsHookpageComponentUi } from './ui/ContactDetailsHookpageComponentUi';
import { contactDetailsHookPagePath } from 'components/navigation/paths';
import { CommonUserDetailsComponent } from '../common-hookpage-component/CommonUserDetailsComponent';

export const ContactDetailsHookpageComponent: React.FC<{ handleSetSeenHookpage: (path: string) => void }> = ({
    handleSetSeenHookpage,
}) => {
    const { t } = useTranslation('contact-details-hookpage');

    const { data: myProfileData, isLoading, loadingError } = useMyProfile();

    const ContactDetailsHookpageComponentWithHandlers = withLoadingAndNoConnectionHandler(
        ContactDetailsHookpageComponentUi,
    );

    return (
        <>
            <CommonUserDetailsComponent title={t('headline')} subsubtitle={t('description')}>
                <ContactDetailsHookpageComponentWithHandlers
                    isLoading={isLoading}
                    myProfileData={myProfileData}
                    hasError={!!loadingError}
                    loadingPlaceholder={<Spinner center />}
                    handleSetSeenHookpage={handleSetSeenHookpage}
                />
                {loadingError && (
                    <ButtonContainer center className={'u-mt'}>
                        <Button
                            secondary
                            onClick={() => handleSetSeenHookpage(contactDetailsHookPagePath())}
                            testId="skip-button"
                        >
                            {t('button.later')}
                        </Button>
                    </ButtonContainer>
                )}
            </CommonUserDetailsComponent>
        </>
    );
};
