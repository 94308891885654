import {
    BaseFinancialDetails,
    CurrencyCode,
    FinanceLeaseFinancialDetails,
    FinanceLeaseGeneralTermsCode,
    InstallmentCreditFinancialDetails,
    InstallmentCreditGeneralTermsCode,
    OperatingLeaseFinancialDetails,
    OperatingLeaseGeneralTermsCode,
} from '@cp-cz/common';

const BaseFinancialDetailsWithAllValues: BaseFinancialDetails = {
    startDate: '2020-10-02',
    endDate: '2022-01-03',
    currentContractAgeMonths: 10,
    currencyCode: CurrencyCode.CZK,
    vehicleReturnDate: '2022-10-11',
    outstandingBalance: 1234.56,
    nextDueDate: '2021-10-11',
    nextDueAmount: 23.5,
    residualValue: 1234,
};

const BaseFinancialDetailsWithMissingValues: BaseFinancialDetails = {
    startDate: '2017-10-11',
    currencyCode: CurrencyCode.CZK,
};

export const InstallmentCreditFinancialDetailsWithAllValues: InstallmentCreditFinancialDetails = {
    ...BaseFinancialDetailsWithAllValues,
    generalTermsCode: InstallmentCreditGeneralTermsCode.OPUS1401,
    financeValueOfContract: 1410,
    downPayment: 5,
    balloonAmount: 20,
};

export const InstallmentCreditFinancialDetailsWithAllValuesAndPaymentHoliday: InstallmentCreditFinancialDetails = {
    ...InstallmentCreditFinancialDetailsWithAllValues,
    paymentHolidayActiveFlag: true,
    paymentHolidayStartDate: '2022-05-01T22:00:00.000Z',
    paymentHolidayEndDate: '2022-05-01T22:00:00.000Z',
};

export const InstallmentCreditFinancialDetailsWithMissingValues: InstallmentCreditFinancialDetails = {
    ...BaseFinancialDetailsWithMissingValues,
    downPayment: 5,
    balloonAmount: 20,
};

export const OperatingLeaseFinancialDetailsWithAllValues: OperatingLeaseFinancialDetails = {
    ...BaseFinancialDetailsWithAllValues,
    generalTermsCode: OperatingLeaseGeneralTermsCode.OPOL1401,
};

export const FinanceLeaseFinancialDetailsWithAllValues: FinanceLeaseFinancialDetails = {
    ...BaseFinancialDetailsWithAllValues,
    generalTermsCode: FinanceLeaseGeneralTermsCode.OPFL1501,
};
