import { ContactDetailsError } from '../types';

export const defaultValidationErrorMessages: ContactDetailsError = {
    email: {
        required: 'Please enter required email address.',
        valid: 'Email address is invalid.',
    },
    phoneNumber: {
        required: 'Please enter required phone number.',
        valid: 'Phone number is invalid.',
    },
};
