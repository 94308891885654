import { License } from '@cp-cz/common';
import { useAnalyticsPageViewTracker } from '@cp-shared/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import frontendLicenses from './licenses-4.json';
import { ThirdPartyLicensesUi } from './ui';
import { useThirdPartyLicenses } from './useThirdPartyLicenses';

const ThirdPartyLicensesWithHandlers = withLoadingAndNoConnectionHandler(ThirdPartyLicensesUi);

export const ThirdPartyLicenses: React.FC = () => {
    const { cmsContent: thirdPartyLicenses, isLoading, loadingError } = useThirdPartyLicenses();
    const content: License[] = frontendLicenses as License[];

    useAnalyticsPageViewTracker('thirdPartyLicenses', !!thirdPartyLicenses);

    return thirdPartyLicenses ? (
        <ThirdPartyLicensesWithHandlers
            isLoading={isLoading}
            thirdPartyLicenses={{
                ...thirdPartyLicenses,
                content,
            }}
            hasError={!!loadingError}
        />
    ) : null;
};
