export function getRegistrationPersonEndpoint(): string {
    return '/registration/person';
}

export function getRegistrationBankIDEndpoint(): string {
    return '/registration/bankid';
}

export function getRegistrationBankIDVerifyEndpoint(): string {
    return '/registration/bankid/verify';
}
