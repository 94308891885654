import { InfoIcon, ValidatedInput } from '@cp-shared/frontend-ui';
import { Fieldset, FormField, Layout } from '@vwfs-its/bronson-react';
import React from 'react';
import { CountryCodeSelection } from './CountryCodeSelection';

export const PhoneInput: React.FC<{
    testId: string;
    inputName: string;
    selectName: string;
    tooltip?: string;
    label: string;
    defaultPrefix?: string;
    handlePrefixChange?: () => void;
}> = ({ testId, inputName, selectName, tooltip, label, defaultPrefix, handlePrefixChange }) => {
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    return (
        <Fieldset.Row>
            <div className={'c-form-field__label'}>
                {label}
                {infoIcon}
            </div>
            <Layout className={'o-layout--flush'}>
                <Layout.Item default={'1/3'}>
                    <CountryCodeSelection
                        name={selectName}
                        testId={testId}
                        defaultPrefix={defaultPrefix}
                        handleChange={handlePrefixChange}
                    />
                </Layout.Item>
                <Layout.Item default={'2/3'}>
                    <FormField type="input">
                        <ValidatedInput
                            reversed
                            type={'tel'}
                            testId={testId}
                            inputMode={'tel'}
                            name={inputName}
                            label={''}
                        />
                    </FormField>
                </Layout.Item>
            </Layout>
        </Fieldset.Row>
    );
};
