import React, { useEffect, useState } from 'react';
import { ContentSection, Heading, Layout } from '@vwfs-its/bronson-react';
import { useTranslation } from 'react-i18next';
import { Greeting } from 'components/greeting';
import { Notification, NotificationStatus } from '@cp-shared/frontend-ui';
import { Contracts, useContracts } from '../../components/contracts';
import { DashboardMarketingCard } from 'components/dashboard-marketing-card/DashboardMarketingCard';
import { hookPageRedirectionPath } from 'components/navigation/paths';
import { useLastLocation } from 'react-router-last-location';
import {
    areContactDetailsChangedStorageKey,
    areContactDetailsConfirmedStorageKey,
    isMarketingConsentSubmittedStorageKey,
} from 'config';

export const DashboardPage: React.FC = () => {
    const { data: contracts, isLoading, loadingError } = useContracts();
    const lastLocation = useLastLocation();
    const [isMarketingConsentSubmitted, setIsMarketingConsentSubmitted] = useState(
        localStorage.getItem(isMarketingConsentSubmittedStorageKey),
    );
    const [areContactDetailsChanged, setAreContactDetailsChanged] = useState(
        localStorage.getItem(areContactDetailsChangedStorageKey),
    );
    const [areContactDetailsConfirmed, setAreContactDetailsConfirmed] = useState(
        localStorage.getItem(areContactDetailsConfirmedStorageKey),
    );
    const { t } = useTranslation('dashboard');
    const title = t('headline');

    useEffect(() => {
        if (!lastLocation?.pathname.includes(hookPageRedirectionPath())) {
            localStorage.setItem(isMarketingConsentSubmittedStorageKey, '');
            localStorage.setItem(areContactDetailsChangedStorageKey, '');
            localStorage.setItem(areContactDetailsConfirmedStorageKey, '');
            setIsMarketingConsentSubmitted('');
            setAreContactDetailsChanged('');
            setAreContactDetailsConfirmed('');
        }
    }, [lastLocation]);

    const RequestSentNotification = () => (
        <Notification
            testId={'requestSentNotification'}
            status={NotificationStatus.success}
            className="u-mb"
            text={t('notification.request-sent.text')}
            headline={t('notification.request-sent.headline')}
        />
    );

    const DataConfirmedNotification = () => (
        <Notification
            testId={'dataConfirmedNotification'}
            status={NotificationStatus.success}
            className="u-mb"
            text={t('notification.data-confirmation')}
        />
    );

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>

            <Greeting />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    {((!isMarketingConsentSubmitted && areContactDetailsConfirmed) ||
                        (isMarketingConsentSubmitted && !areContactDetailsChanged && !areContactDetailsConfirmed) ||
                        (isMarketingConsentSubmitted && areContactDetailsConfirmed)) && <DataConfirmedNotification />}

                    {((!isMarketingConsentSubmitted && areContactDetailsChanged) ||
                        (isMarketingConsentSubmitted && areContactDetailsChanged)) && <RequestSentNotification />}

                    <Contracts
                        contracts={contracts}
                        isLoadingContracts={isLoading}
                        contractsLoadingError={!!loadingError}
                    />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <DashboardMarketingCard
                        contracts={contracts}
                        isLoadingContracts={isLoading}
                        contractsLoadingError={!!loadingError}
                    />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
