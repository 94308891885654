import { IconFooter } from '@cp-cz/common';
import { IconFooter as IconFooterShared, useAnalyticsActionTracker, useAuthentication } from '@cp-shared/frontend-ui';
import { IconFooterItemCMS } from '@cp-shared/apis';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentPageName } from '../../../../utils';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter }> = ({ iconFooter }) => {
    const location = useLocation();
    const currentPageName = getCurrentPageName(location);
    const { isAuthenticated } = useAuthentication();

    const { onAction: onCallUs } = useAnalyticsActionTracker('onClickCallUsLink');
    const { onAction: onEmailUs } = useAnalyticsActionTracker('onClickEmailLink');

    if (!iconFooter) {
        return null;
    }

    let iconFooterItems: IconFooterItemCMS[];

    // it changes link url of the second iconFooter (should be contact us), when user is not logged in
    if (!isAuthenticated && iconFooter.items[1].icon === 'semantic-mail') {
        iconFooterItems = [
            iconFooter.items[0],
            {
                ...iconFooter.items[1],
                linkUrl: 'https://www.vwfs.cz/kontakty.html#kontaktujte_nas',
                normalLink: true,
            },
            ...iconFooter.items.slice(2),
        ];
    } else iconFooterItems = iconFooter.items;

    return (
        <IconFooterShared
            items={iconFooterItems}
            trackingFunctions={{
                callUs: (): void => onCallUs('Call us', currentPageName),
                emailUs: (): void => onEmailUs('Contact', currentPageName),
            }}
        />
    );
};
