import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { MyProfileLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared/frontend-ui';
import React from 'react';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';

export const MyProfile: React.FC = () => {
    const { data: myProfileData, isLoading, loadingError } = useMyProfile();

    useAnalyticsPageViewTracker('profile', !!myProfileData);

    const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

    return (
        <MyProfileWithHandlers
            isLoading={isLoading}
            myProfileData={myProfileData}
            hasError={!!loadingError}
            loadingPlaceholder={<MyProfileLoadingPlaceholder />}
        />
    );
};
