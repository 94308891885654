import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { CpDataApi } from './axiosInstances';
import { apiGatewayConsumerName, currentBrand, integrationApiBaseUrl } from 'config';
import { useAuthentication, AuthenticationHookResult } from '@cp-shared/frontend-ui';

const extractSub = (tokenParsed: object): string => {
    const { idpSub } = tokenParsed as { idpSub: string };
    return idpSub;
};
const prepareHeaders = (auth: AuthenticationHookResult): object => {
    if (!auth.isAuthenticated) return {};
    if (process?.env?.NODE_ENV === 'production') {
        return {
            'x-consumer-name': apiGatewayConsumerName,
            'x-authorization-token': `Bearer ${auth.token}`,
        };
    } else {
        return {
            'x-consumer-id': '12351',
            'x-request-idpsub': extractSub(auth.tokenParsed),
            'x-access-token': auth.token,
        };
    }
};
export function useAxiosWithAuthentication(): void {
    const auth = useAuthentication();

    if (auth.isAuthenticated) {
        const headers = prepareHeaders(auth);
        Object.assign(CpDataApi.defaults.headers, headers, { 'x-cp-brand': currentBrand });
        if (process?.env?.NODE_ENV === 'production') {
            CpDataApi.defaults.baseURL = integrationApiBaseUrl;
        }
    }
}

export function useAxiosWithCurrentLanguage(): void {
    const { i18n } = useTranslation(undefined, { useSuspense: false });
    const { language } = i18n;
    useEffect(() => {
        if (language) {
            Object.assign(CpDataApi.defaults.headers, {
                'Accept-Language': language,
            });
        }
    }, [language]);
}
