import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@vwfs-its/bronson-react';
import { ValidatedCustomSelect, ValidatedRadioButtonGroup, ValidatedTextarea } from '@cp-shared/frontend-ui';
import { getDevinculationContractOptions, getRadioItems } from '../utils';
import { Contract, EndOfPledgeOptions } from '@cp-cz/common';
import { RequestFormResultType } from '../initialValues';

type DevinculationFormSectionProps = { values: RequestFormResultType; contracts: Contract[] };

export const DevinculationFormSection: React.FC<DevinculationFormSectionProps> = ({ values, contracts }) => {
    const { t } = useTranslation('open-request');

    return (
        <>
            <Layout.Item>
                <ValidatedCustomSelect
                    label={t('form.contract-selection-label')}
                    testId={'custom-contract-select-devinculation'}
                    icon="semantic-expand"
                    name="devinculationContractNumber"
                    customSelectItems={getDevinculationContractOptions(contracts, t)}
                    defaultOptionKey={values.devinculationContractNumber}
                />
            </Layout.Item>
            <Layout.Item center default="1/1" s="1/1">
                <ValidatedRadioButtonGroup
                    testId={'radio-devinculation'}
                    radioButtons={getRadioItems(t)}
                    name="endOfPledge"
                    label={t('form.end-of-pledge-label')}
                />

                {values.endOfPledge === EndOfPledgeOptions.NON_AUTHORIZED_SERVICE && (
                    <p style={{ paddingTop: '15px' }}>
                        {t('form.form-fields.end-of-pledges-options.selected-non-authorized-service')}
                    </p>
                )}
            </Layout.Item>
            <Layout.Item>
                <ValidatedTextarea
                    label={t('form.comment-label')}
                    name={'comment'}
                    testId={`test-comment`}
                    rows={4}
                    enableMaxLength={true}
                    maxLength={2000}
                />
            </Layout.Item>
        </>
    );
};
