import * as Yup from 'yup';
import { ContactDetails } from '../../apis';
import { defaultValidationErrorMessages } from '../messages';
import { ContactDetailsError } from '../types';

const regexRules = {
    email: /(?=^[\w%+-]+(\.[\w-%+-]|[\w-%+-])*@[\w-]+(\.[\w-]|[\w-])*\.[\w-]{2,}$)(?=^.{1,64}@.{4,254}$)(?=^.{6,256}$)/,
    phoneNumberCZ: /^([6-7]{1})(\d{8})$/,
    phoneNumberSK: /^([9||0]{1})(\d{8})$/,
    phoneNumberOther: /^[0-9]{0,15}$/,
};

export const contactDetailsValidationSchema = (
    errorMessages: ContactDetailsError = defaultValidationErrorMessages,
): Yup.SchemaOf<ContactDetails> =>
    Yup.object().shape({
        email: Yup.string().required(errorMessages.email.required).matches(regexRules.email, errorMessages.email.valid),
        phoneNumber: Yup.string()
            .required(errorMessages.phoneNumber.required)
            .when('countryPrefixDigits', {
                is: (val: string) => val === 'CZ' || val.includes('420'),
                then: Yup.string().matches(regexRules.phoneNumberCZ, errorMessages.phoneNumber.valid),
            })
            .when('countryPrefixDigits', {
                is: (val: string) => val === 'SK' || val.includes('421'),
                then: Yup.string().matches(regexRules.phoneNumberSK, errorMessages.phoneNumber.valid),
            })
            .when('countryPrefixDigits', {
                is: (val: string) => val !== 'CZ' && val !== 'SK' && !val.includes('421') && !val.includes('420'),
                then: Yup.string().matches(regexRules.phoneNumberOther, errorMessages.phoneNumber.valid),
            }),
        countryPrefixDigits: Yup.string().required(errorMessages.phoneNumber.required),
    });
