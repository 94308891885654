import React from 'react';
import { formatAsFileSize } from '@cp-cz/common';
import { FileUpload as SharedFileUpload } from '@cp-shared/frontend-ui';
import { useTranslation } from 'react-i18next';

export type FileUploadProps = {
    name: string;
    multiple?: boolean;
    maxFileSize?: number;
    maxFileSizePerFile?: number;
    validFileFormats?: string[];
    sizeError?: string | React.ReactNode;
    typeError?: string | React.ReactNode;
    sizeAndTypeError?: string | React.ReactNode;
    descriptionText?: string;
    descriptionSupplementaryText?: string;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    name,
    multiple,
    maxFileSize,
    maxFileSizePerFile,
    validFileFormats,
    sizeError,
    typeError,
    sizeAndTypeError,
    descriptionText,
    descriptionSupplementaryText,
}) => {
    const { t } = useTranslation('file-upload');

    return (
        <SharedFileUpload
            name={name}
            buttonText={t('send-button')}
            cancelLabel={t('cancel-button')}
            fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
            multiple={multiple}
            maxFileSize={maxFileSize}
            maxFileSizePerFile={maxFileSizePerFile}
            validFileFormats={validFileFormats}
            sizeError={sizeError}
            typeError={typeError}
            sizeAndTypeError={sizeAndTypeError}
            descriptionSupplementaryText={descriptionSupplementaryText}
            descriptionText={descriptionText}
        />
    );
};
