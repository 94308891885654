import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { GreetingUi } from './ui';
import { useGreeting } from './useGreeting';

const GreetingWithHandlers = withLoadingAndNoConnectionHandler(GreetingUi);

export const Greeting: React.FC = () => {
    const { data: greeting, isLoading } = useGreeting();

    return <GreetingWithHandlers isLoading={isLoading} greeting={greeting} hasError={false} />;
};
