import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonUserDetailsComponent } from '../common-hookpage-component/CommonUserDetailsComponent';
import {
    EditStatus,
    MarketingPreferenceOptions,
    MarketingPreference,
    marketingPreferencesValidationSchema,
    getMarketingPreferencesEndpoint,
    getGreetingEndpoint,
} from '@cp-cz/common';
import {
    InfoIcon,
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    ValidatedRadioButtonGroup,
} from '@cp-shared/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout, FormField } from '@vwfs-its/bronson-react';
import { isAfterMarketingConsentHookPageStorageKey, isMarketingConsentSubmittedStorageKey } from 'config';
import { Formik } from 'formik';
import { CpDataApi } from '../../../cp-xhr';
import { marketingPreferencesHookPagePath } from '../../navigation/paths';
import { capitalizeFirstWord } from 'utils';

export const MarketingPreferencesHookpageComponent: React.FC<{ handleSetSeenHookpage: (path: string) => void }> = ({
    handleSetSeenHookpage,
}) => {
    const { t } = useTranslation('marketing-preferences-hookpage');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editStatus, setEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [salutation, setSalutation] = useState<string>('');

    const proceedToHookPageRedirection = (): void => {
        handleSetSeenHookpage(marketingPreferencesHookPagePath());
    };

    useEffect(() => {
        CpDataApi.get(getGreetingEndpoint())
            .then((response) => {
                const customerName = capitalizeFirstWord(response.data.name);
                setSalutation(customerName);
            })
            .catch((_) => setSalutation(t('default-salutation')));
    }, [t]);

    const onSubmit = (values: MarketingPreference): void => {
        setIsSubmitting(true);

        const marketingPreferencesBody: MarketingPreference = {
            directMarketing: values.directMarketing === MarketingPreferenceOptions.CONSENT ? 'Y' : 'N',
            indirectMarketing: values.indirectMarketing === MarketingPreferenceOptions.CONSENT ? 'Y' : 'N',
        };

        CpDataApi.post(getMarketingPreferencesEndpoint(), marketingPreferencesBody)
            .then(() => {
                setEditStatus(EditStatus.SUCCESS);
                window.localStorage.setItem(isMarketingConsentSubmittedStorageKey, 'true');
                window.localStorage.setItem(isAfterMarketingConsentHookPageStorageKey, 'true');
                proceedToHookPageRedirection();
            })
            .catch(() => {
                setEditStatus(EditStatus.ERROR);
                window.localStorage.setItem(isMarketingConsentSubmittedStorageKey, '');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const getDirectMarketingRadioItems = (t: Function) => [
        {
            label: t('form.consent-label'),
            value: MarketingPreferenceOptions.CONSENT,
        },
        {
            label: t('form.dissent-label'),
            value: MarketingPreferenceOptions.DISSENT,
        },
    ];

    const getIndirectMarketingRadioItems = (t: Function) => [
        {
            label: t('form.consent-label'),
            value: MarketingPreferenceOptions.CONSENT,
        },
        {
            label: t('form.dissent-label'),
            value: MarketingPreferenceOptions.DISSENT,
        },
    ];

    const getErrorMessages = (t: Function) => {
        return {
            directMarketing: {
                required: t('form.validation'),
            },
            indirectMarketing: {
                required: t('form.validation'),
            },
        };
    };

    return (
        <>
            {salutation ? (
                <CommonUserDetailsComponent
                    title={t('headline')}
                    subsubtitle={t('description', { vocative: salutation })}
                    isLoadingSpinner={isSubmitting}
                >
                    {editStatus === EditStatus.ERROR && (
                        <Notification
                            testId={'error-notification'}
                            status={NotificationStatus.error}
                            text={t('message.error.text')}
                            className={'u-mb'}
                        />
                    )}
                    <Formik
                        initialValues={{ directMarketing: '', indirectMarketing: '' }}
                        onSubmit={onSubmit}
                        validationSchema={marketingPreferencesValidationSchema(getErrorMessages(t))}
                    >
                        {({ submitForm }): JSX.Element => (
                            <Form onSubmit={preventSubmit()} data-testid="marketing-preferences-form">
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item center default="1/1">
                                                <FormField
                                                    type={'other'}
                                                    labelText={t('form.direct-marketing.label')}
                                                    infoIcon={<InfoIcon text={t('form.direct-marketing.tooltip')} />}
                                                >
                                                    <ValidatedRadioButtonGroup
                                                        className={'u-mt-xsmall'}
                                                        testId={'radio-direct-marketing'}
                                                        radioButtons={getDirectMarketingRadioItems(t)}
                                                        name="directMarketing"
                                                    />
                                                </FormField>
                                            </Layout.Item>
                                            <Layout.Item center default="1/1">
                                                <FormField
                                                    type={'other'}
                                                    labelText={t('form.indirect-marketing.label')}
                                                    infoIcon={
                                                        <InfoIcon
                                                            text={
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: t('form.indirect-marketing.tooltip'),
                                                                    }}
                                                                ></span>
                                                            }
                                                        />
                                                    }
                                                >
                                                    <ValidatedRadioButtonGroup
                                                        className={'u-mt-xsmall'}
                                                        testId={'radio-indirect-marketing'}
                                                        radioButtons={getIndirectMarketingRadioItems(t)}
                                                        name="indirectMarketing"
                                                    />
                                                </FormField>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center className={'u-mt'}>
                                            <Button
                                                secondary
                                                testId="backButton"
                                                onClick={() => proceedToHookPageRedirection()}
                                            >
                                                {t('button.skip')}
                                            </Button>
                                            <Button onClick={submitForm} testId="submit-button" type="submit">
                                                {t('button.confirm')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </CommonUserDetailsComponent>
            ) : (
                <Spinner fullPage />
            )}
        </>
    );
};
