import { BankIDErrorCode, RegistrationBankIDVerifyErrorCode, RegistrationBankIDVerifyPayload } from '@cp-cz/common';

export const getBankIDParams = (params: URLSearchParams): RegistrationBankIDVerifyPayload => {
    const xTrackingId = params.get('x-tracking-id') || '';
    const caseId = params.get('case-id') || '';

    return { xTrackingId, caseId };
};

export const getBankIDVerifyErrorMessage = (code: RegistrationBankIDVerifyErrorCode | BankIDErrorCode): string => {
    switch (code) {
        case RegistrationBankIDVerifyErrorCode.CUSTOMER_ALREADY_REGISTERED:
            return 'customer-already-registered';
        case RegistrationBankIDVerifyErrorCode.CUSTOMER_DATA_DONOT_MATCH:
            return 'customer-data-dont-match';
        default:
            return 'verification-failed';
    }
};
