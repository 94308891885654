import { GroupedInstallment, Installment, InstallmentStateCode, InstallmentTypeCode } from '../../apis';

const getNewValue = (currentValue: number | undefined, interestAmount: number | undefined): number | undefined => {
    if (interestAmount === undefined) return currentValue;
    return (currentValue ? currentValue : 0) + interestAmount;
};

const setGroupedInstallmentValues = (groupedInstallment: GroupedInstallment, partialInstallment: Installment): void => {
    const { currencyCode, dueDate, installmentStateCode, installmentTypeCode, interestAmount } = partialInstallment;
    if (installmentStateCode === InstallmentStateCode.L) {
        switch (installmentTypeCode) {
            case InstallmentTypeCode.CAP:
                groupedInstallment.principalSum = getNewValue(groupedInstallment.principalSum, interestAmount);
                break;
            case InstallmentTypeCode.INT:
                groupedInstallment.interest = getNewValue(groupedInstallment.interest, interestAmount);
                break;
            case InstallmentTypeCode.SRV:
                groupedInstallment.servicesFee = getNewValue(groupedInstallment.servicesFee, interestAmount);
                break;
            case InstallmentTypeCode.INS:
                groupedInstallment.insurance = getNewValue(groupedInstallment.insurance, interestAmount);
                break;
            case InstallmentTypeCode.ACI:
                groupedInstallment.interest = getNewValue(groupedInstallment.interest, interestAmount);
                break;
            case InstallmentTypeCode.TOTAL:
                groupedInstallment.dueDate = dueDate;
                groupedInstallment.currencyCode = currencyCode;
                groupedInstallment.total = interestAmount;
                break;
        }
    }
};

const createNewGroupedInstallment = (partialInstallment: Installment): GroupedInstallment => {
    const { installmentNumber } = partialInstallment;
    const newGroupedInstallment: GroupedInstallment = {
        installmentNumber: installmentNumber || 0,
    };
    setGroupedInstallmentValues(newGroupedInstallment, partialInstallment);
    return newGroupedInstallment;
};

export const groupPartialInstallments = (partialInstallments: Installment[]): GroupedInstallment[] => {
    const result: GroupedInstallment[] = partialInstallments.reduce(
        (groupedlInstallments: GroupedInstallment[], partialInstallment: Installment): GroupedInstallment[] => {
            const existingGroupedInstallment = groupedlInstallments.find(
                (groupedlInstallment) => groupedlInstallment.installmentNumber === partialInstallment.installmentNumber,
            );
            if (existingGroupedInstallment) {
                setGroupedInstallmentValues(existingGroupedInstallment, partialInstallment);
            } else {
                groupedlInstallments.push(createNewGroupedInstallment(partialInstallment));
            }
            return groupedlInstallments;
        },
        [],
    );
    return result.sort((a: GroupedInstallment, b: GroupedInstallment) => a.installmentNumber - b.installmentNumber);
};
