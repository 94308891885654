export function getRequestSendingEndpoint(): string {
    return `/request/send`;
}

export function getDevinculationRequestSendingEndpoint(): string {
    return `/devinculation-request/send`;
}

export function getEndOfTermCalculationRequestSendingEndpoint(): string {
    return `/end-of-term-calculation-request/send`;
}
