import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@vwfs-its/bronson-react';
import { ValidatedTextarea } from '@cp-shared/frontend-ui';

import { FileUpload } from 'components/file-upload/FileUpload';

export const DefaultFormSection: React.FC = () => {
    const { t } = useTranslation('open-request');
    const maxFileSize = 4 * 1024 * 1024;
    const validFileFormats = ['pdf', 'bmp', 'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'docx', 'doc'];

    return (
        <>
            <Layout.Item>
                <ValidatedTextarea
                    label={t('form.question-label')}
                    name={'question'}
                    testId={`test-question`}
                    rows={4}
                    enableMaxLength={true}
                    maxLength={2000}
                />
            </Layout.Item>
            <Layout.Item>
                <h5>{t('form.files-upload.description-title')}</h5>
                <p>{t('form.files-upload.description')}</p>
                <p>{t('form.files-upload.description-formats')}</p>
                <FileUpload
                    name={'files'}
                    descriptionText={t('form.files-upload.upload-title')}
                    descriptionSupplementaryText={t('form.files-upload.upload-description')}
                    maxFileSize={maxFileSize}
                    validFileFormats={validFileFormats}
                    maxFileSizePerFile={maxFileSize}
                    multiple={false}
                    sizeError={t('form.validation.files.maxTotalSize')}
                    typeError={t('form.validation.files.fileType')}
                />
            </Layout.Item>
        </>
    );
};
