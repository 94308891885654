import { IdentificationForm } from './identification';
import { SendAuthorizationCodeError, RegistrationErrorType, RegistrationResult } from '@cp-cz/common';
import { Error } from '@cp-shared/common-utilities';

export const identificationFormDataWithAllValues: IdentificationForm = {
    contractId: '111111',
    registrationPlateCode: '1111112',
};

export const RegistrationResponseWithIdentityDoesNotMatch: Error<RegistrationErrorType> = {
    status: 404,
    code: 'IDENTITY_DOES_NOT_MATCH',
    message: 'identity does not match',
};

export const RegistrationResponseWithContractNotAllowed: Error<RegistrationErrorType> = {
    status: 405,
    code: 'CONTRACT_NOT_ALLOWED',
    message: 'contract not allowed',
};

export const RegistrationResponseWithProductNotSupported: Error<RegistrationErrorType> = {
    status: 406,
    code: 'PRODUCT_NOT_SUPPORTED',
    message: 'product not supported',
};

export const RegistrationResponseWithRequestTimeout: Error<RegistrationErrorType> = {
    status: 408,
    code: 'REQUEST_TIMEOUT',
    message: 'request timeout',
};

export const RegistrationResponseWithAlreadyRegisteredError: Error<RegistrationErrorType> = {
    status: 409,
    code: 'CUSTOMER_ALREADY_REGISTERED',
    message: 'customer already registered',
};

export const RegistrationResponseWithTooManyRequests: Error<RegistrationErrorType> = {
    status: 429,
    code: 'TOO_MANY_REQUESTS',
    message: 'to many requests',
};

export const SendAuthorizationCodeWithInvalidPhoneNumberError: Error<SendAuthorizationCodeError> = {
    status: 417,
    code: 'INVALID_PHONE_NUMBER',
    message: 'invalid phone number',
};

export const SendAuthorizationCodeExampleResponse: RegistrationResult = {
    phoneNumberEnding: '789',
    customerId: '887766',
};
