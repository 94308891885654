import { Service } from '@cp-cz/common';

export const ServiceDetailWithAllValues: Service = {
    itemCode: 'RADIO',
    itemName: 'Radio',
    endDate: '2021-01-01',
    demandPcs: 'DemandPcs',
};

export const ServiceDetailsWithDemandPcsWithAllValues: Service = {
    itemCode: 'SUMER',
    itemName: 'Letní pneumatiky',
    endDate: '2022-02-02',
    demandPcs: 'DemandPcs',
};

export const ServicesDetailsWithAllValues: Service[] = [
    ServiceDetailWithAllValues,
    ServiceDetailsWithDemandPcsWithAllValues,
];

export const ServicesDetailsWithMissingValues: Service[] = [];
