import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button, ContentSection, Fieldset, Layout, Modal, Spinner } from '@vwfs-its/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared/frontend-ui';
import {
    Contract,
    DevinculationRequest,
    EndOfTermCalculationReason,
    EndOfTermCalculationRequest,
    frontEndOpenRequestDevinculationValidationSchema,
    frontEndOpenRequestEndOfTermCalculationValidationSchema,
    frontEndOpenRequestValidationSchema,
    getDevinculationRequestSendingEndpoint,
    getEndOfTermCalculationRequestSendingEndpoint,
    getRequestSendingEndpoint,
    Request,
} from '@cp-cz/common';
import { getInitialValues, RequestFormResultType } from './initialValues';
import { ContractSelection, devinculationKey, endOfTermCalculationKey } from './contract-selection/ContractSelection';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';
import { Base64File } from '@cp-shared/apis';
import { getBase64 } from '../../file-upload/utils';
import {
    getContractByContractNumber,
    getEndOfTermCalculationContracts,
    getEndOfTermCalculationErrorMessages,
    getErrorMessages,
    getErrorMessagesDevinculation,
    getRegistrationPlateCode,
    mapReasonOptionToValue,
    mapSelectedPledgeOptionToValue,
} from './utils';
import { DefaultFormSection } from './default-form-section';
import { DevinculationFormSection } from './devinculation-form-section';
import { EndOfTermCalculationFormSection } from './end-of-term-calculation-form-section';

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    NONE = 'NONE',
}

enum ViewType {
    DEFAULT = 'DEFAULT',
    DEVINCULATION = 'DEVINCULATION',
    END_OF_TERM_CALCULATION = 'END_OF_TERM_CALCULATION',
}

export type FormViewProps = { contracts?: Contract[] };

export const FormView: React.FC<FormViewProps> = ({ contracts = [] }) => {
    const { t } = useTranslation('open-request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentView, setCurrentView] = useState<ViewType>(ViewType.DEFAULT);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    if (!contracts) {
        return null;
    }

    const endOfTermCalculationContracts = getEndOfTermCalculationContracts(contracts);

    const handleViewChange = (value: string): void => {
        switch (value) {
            case devinculationKey:
                setCurrentView(ViewType.DEVINCULATION);
                break;
            case endOfTermCalculationKey:
                setCurrentView(ViewType.END_OF_TERM_CALCULATION);
                break;
            default:
                setCurrentView(ViewType.DEFAULT);
                break;
        }
    };

    const handleSubmit = async (values: RequestFormResultType, { resetForm }: { resetForm: Function }) => {
        setIsSubmitting(true);
        const {
            optionKey,
            question = '',
            files = [],
            devinculationContractNumber,
            comment,
            endOfPledge,
            endOfTermCalculation,
        } = values;
        let body: Request | DevinculationRequest | EndOfTermCalculationRequest;
        let requestEndpointPath: string;
        let analyticsSuccessMessage: string;

        if (currentView === ViewType.END_OF_TERM_CALCULATION) {
            const { contractNumber, comment, otherExplanation, reason } = endOfTermCalculation;
            body = {
                contractNumber: endOfTermCalculation.contractNumber,
                registrationPlateCode: getRegistrationPlateCode(endOfTermCalculationContracts, contractNumber),
                reason: mapReasonOptionToValue(t, reason),
                otherExplanation: reason === EndOfTermCalculationReason.OTHER ? otherExplanation : '',
                comment,
            };
            requestEndpointPath = getEndOfTermCalculationRequestSendingEndpoint();
        } else if (currentView === ViewType.DEVINCULATION) {
            analyticsSuccessMessage = `end of pledge option: ${endOfPledge}`;
            body = {
                devinculationContractNumber,
                comment,
                endOfPledge: mapSelectedPledgeOptionToValue(endOfPledge, t),
            };
            requestEndpointPath = getDevinculationRequestSendingEndpoint();
        } else {
            analyticsSuccessMessage = `question: ${question}`;
            const filePromises: Promise<Base64File>[] = files.map((file) => getBase64(file));
            const base64Files = await Promise.all(filePromises);
            const encryptedContractNumber =
                getContractByContractNumber(contracts, optionKey)?.encryptedContractNumber || '';
            body = {
                contractNumber: encryptedContractNumber,
                question,
                files: base64Files,
            };
            requestEndpointPath = getRequestSendingEndpoint();
        }

        CpDataApi.post(requestEndpointPath, body)
            .then(() => {
                onSuccess(analyticsSuccessMessage);
                resetForm({
                    values: getInitialValues(),
                });
                setMessageType(MessageType.SUCCESS);
                setCurrentView(ViewType.DEFAULT);
            })
            .catch(() => {
                onError();
                setMessageType(MessageType.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalConfirm = () => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    const getValidationSchema = () => {
        switch (currentView) {
            case ViewType.DEVINCULATION:
                return frontEndOpenRequestDevinculationValidationSchema(getErrorMessagesDevinculation(t));
            case ViewType.END_OF_TERM_CALCULATION:
                return frontEndOpenRequestEndOfTermCalculationValidationSchema(getEndOfTermCalculationErrorMessages(t));
            default:
                return frontEndOpenRequestValidationSchema(getErrorMessages(t));
        }
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ submitForm, values, setFieldValue, errors, touched }) => (
                <Form onSubmit={(e) => e.preventDefault()} data-testid="request-form">
                    <ContentSection>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ContractSelection
                                            contracts={contracts}
                                            handleViewChange={handleViewChange}
                                            hideEndOfTermCalculation={!endOfTermCalculationContracts.length}
                                        />
                                    </Layout.Item>
                                    {currentView === ViewType.DEVINCULATION && (
                                        <DevinculationFormSection values={values} contracts={contracts} />
                                    )}
                                    {currentView === ViewType.END_OF_TERM_CALCULATION && (
                                        <EndOfTermCalculationFormSection
                                            endOfTermCalculationValues={values.endOfTermCalculation}
                                            contracts={endOfTermCalculationContracts}
                                            setFieldValue={setFieldValue}
                                            errors={errors?.endOfTermCalculation}
                                            touched={touched?.endOfTermCalculation}
                                        />
                                    )}
                                    {currentView === ViewType.DEFAULT && <DefaultFormSection />}
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <div className="u-text-center">
                                    {isSubmitting ? (
                                        <Spinner center />
                                    ) : (
                                        <Button testId={'submit-button'} type="submit" onClick={submitForm}>
                                            {t('form.submit-button')}
                                        </Button>
                                    )}
                                </div>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    <Modal
                        shown={messageType === MessageType.ERROR}
                        status="error"
                        title={t('modal.error.title')}
                        testId={'error-modal'}
                        onClose={handleModalClose}
                        onCancel={handleModalClose}
                        onClickOutside={handleModalClose}
                    >
                        {t('modal.error.description')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('modal.success.title')}
                        onClose={handleModalClose}
                        onConfirm={handleSuccessModalConfirm}
                        onClickOutside={handleModalClose}
                        buttonConfirmText={t('navigation:navigation.dashboard')}
                        testId={'success-modal'}
                    >
                        {t('modal.success.description')}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
