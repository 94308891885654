import * as H from 'history';
import { capitalize } from 'lodash';

export const getCurrentPageName = (location: H.Location): string => {
    const pagePath = location.pathname.split('/')[1];
    if (!pagePath) {
        return 'Home';
    }
    const pageName = pagePath === 'my-profile' ? 'Profile' : pagePath.replace(/-/g, ' ');
    return capitalize(pageName);
};
