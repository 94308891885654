import React from 'react';

import { AccordionItem, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared/frontend-ui';
import { useTranslation } from 'react-i18next';
import { formatAsDate, Service } from '@cp-cz/common';

export const ServicesDetails: React.FC<{ servicesDetails?: Service[] }> = ({ servicesDetails }) => {
    const { t } = useTranslation('services-details');
    if (!servicesDetails?.length) {
        return null;
    }

    const isWithDemandPcs = (itemName?: string, itemCode?: string): boolean => {
        const pcsItemCodes = ['SUMER', 'WINTR'];
        const pcsItemNames = ['Letní pneumatiky', 'Zimní pneumatiky'];
        return !!((itemCode && pcsItemCodes.includes(itemCode)) || (itemName && pcsItemNames.includes(itemName)));
    };

    const itemList = servicesDetails
        .filter(
            ({ itemName, itemCode, endDate, demandPcs }) =>
                !!itemName && !!endDate && (isWithDemandPcs(itemName, itemCode) ? !!demandPcs : true),
        )
        .map(({ itemName, itemCode, endDate, demandPcs }, index): DefinitionListItem => {
            const getValue = (): string => {
                const demandPcsPrefix = isWithDemandPcs(itemName, itemCode) ? `${demandPcs} - ` : '';
                return `${demandPcsPrefix}${t('service-item-value')} ${formatAsDate(endDate)}` || '';
            };

            return {
                label: itemName,
                value: getValue(),
                testId: `service-item-${index}`,
            };
        });

    if (!itemList.length) {
        return null;
    }

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <DefinitionListHorizontal list={itemList} />
        </AccordionItem>
    );
};
