import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, ContractActionItemType, ContractActions, useAnalyticsActionTracker } from '@cp-shared/frontend-ui';
import { Contract, StateConsolidatedCode } from '@cp-cz/common';
import { ContractDetails } from '../../contract-details';
import { amortizationTablePath } from '../../../navigation/paths';

export const ContractBody: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');

    const { isActive, encryptedContractNumber } = contract;

    const actionItems = ((): ContractActionItemType[] => {
        const actionItems: ContractActionItemType[] = [];
        if (
            isActive &&
            (contract.stateConsolidatedCode === StateConsolidatedCode.ACTCTR ||
                contract.stateConsolidatedCode === StateConsolidatedCode.PEN ||
                contract.stateConsolidatedCode === StateConsolidatedCode.FRZ)
        ) {
            actionItems.push({
                iconName: 'semantic-calendar',
                pageUrl: amortizationTablePath(encryptedContractNumber),
                label: t('contract-actions.amortization-table'),
            });
        }

        return actionItems;
    })();

    const renderActionItemsIfExist = (): React.ReactNode => {
        return actionItems.length ? (
            <div className="u-pb-small u-pt-small">
                <ContractActions contractActionItems={actionItems} />
            </div>
        ) : null;
    };

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('financial-details:headline'):
                return 'Financial details';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
            case t('services-details:headline'):
                return 'Products / Services included';
            case t('contract-parties:headline'):
                return 'Contract parties';
        }
    };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    return (
        <Accordion onChange={handleOnChange} lazyRender>
            {renderActionItemsIfExist()}
            <ContractDetails contract={contract} />
        </Accordion>
    );
};
