import { AddressValidatorErrorMessagesType } from '../types';

export const defaultAddressValidationErrorMessages: AddressValidatorErrorMessagesType = {
    country: {
        required: 'Country is empty',
    },
    city: {
        required: 'City is empty',
        valid: 'Municipality is not valid.',
    },
    street: {
        valid: 'Street must contain max 50 characters',
    },
    streetNumber: {
        required: 'streetNumber is empty',
        valid: 'Identification and/or orientation number is not valid.',
    },
    zipCode: {
        required: 'zipCode is empty',
        valid: 'ZIP code is not valid.',
    },
    files: {
        required: 'Address file is required',
        maxFiles: 'Only one file is allowed to be uploaded',
        fileType: 'Only pdf, bmp, gif, jpeg, jpg, png, tif, tiff, docx, doc types are allowed',
        maxTotalSize: 'Total size of uploaded file is larger than 4MB',
    },
};
