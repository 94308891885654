import React from 'react';
import { Faq } from '@cp-cz/common';
import { Faq as FaqShared, HeroImage, useAnalyticsActionTracker } from '@cp-shared/frontend-ui';
import { ContentSection } from '@vwfs-its/bronson-react';

type FaqUiProps = {
    faq?: Faq;
};

export const FaqUi: React.FC<FaqUiProps> = ({ faq }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');
    if (!faq) {
        return null;
    }
    const { teaser, content } = faq || {};

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    let openItems = [];
    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    const { header, items } = content || { header: '', items: [] };

    const contentComponent = <FaqShared onChange={handleOnChange} header={header} items={items} descriptionText="" />;
    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};
