import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Fieldset, Form, Button, Layout } from '@vwfs-its/bronson-react';
import {
    CleaveInput,
    preventSubmit,
    useAnalyticsPageViewTracker,
    useAnalyticsFormTracker,
    useAnalyticsActionTracker,
    Notification,
    NotificationStatus,
    Spinner,
    useAuthentication,
} from '@cp-shared/frontend-ui';
import { hookPageRedirectionPath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { smsIdentificationValidationSchema } from './validation';
import {
    ConfirmAuthorizationError,
    getRegistrationPersonEndpoint,
    getVerifyCodeEndpoint,
    Registration as RegistrationInterface,
    SendAuthorizationCodeError,
    ValidateTanStatus,
} from '@cp-cz/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared/common-utilities';
import { parseErrorResponse } from '@cp-shared/frontend-integration';
import { getAbsoluteUrlWithParams } from '../../../../utils/urlSearchParams';
import { acceptedConsentDateStorageKey, consentValidDate } from 'config';
import { resetHookPageStorage } from 'utils';
import { getHashedUserId } from '../../../hook-page-redirection/utils';

export const testIds = {
    smsToken: 'smsToken',
    submit: 'submitAuthorization',
    resendSms: 'resendSms',
};

const getResendSmsErrorTranslationLabel = (
    errorCode: WithDefaultCpIntegrationErrors<SendAuthorizationCodeError>,
): string => {
    switch (errorCode) {
        case 'INVALID_PHONE_NUMBER':
            return 'identification.error-notification.invalid-phone-number';
        default:
            return 'translation:error.noConnection.text';
    }
};

const getConfirmAuthenticationCodeErrorTranslationLabel = (
    errorCode: WithDefaultCpIntegrationErrors<ConfirmAuthorizationError>,
): string => {
    switch (errorCode) {
        case 'INVALID_CODE':
            return 'sms-identification.error.code-invalid';
        default:
            return 'sms-identification.error.api-error';
    }
};

export type SmsIdentificationProps = {
    phoneNumberEnding: string;
    customerId: string;
    formValuesForStep1: RegistrationInterface;
};

export const SmsIdentification: React.FC<SmsIdentificationProps> = ({
    phoneNumberEnding,
    customerId,
    formValuesForStep1,
}) => {
    const { t } = useTranslation('registration');
    const auth = useAuthentication();
    const [resendSmsRequest, setResendSmsRequest] = useState<{
        isLoading: boolean;
        errorTranslationLabel?: string;
    }>({
        isLoading: false,
    });
    const [confirmAuthenticationCodeRequest, setConfirmAuthenticationCodeRequest] = useState<{
        isLoading: boolean;
        errorTranslationLabel?: string;
    }>({
        isLoading: false,
    });

    useAnalyticsPageViewTracker('enterSmsCode', !!phoneNumberEnding && !!customerId);
    const { onError: trackOnError } = useAnalyticsFormTracker({
        confirmError: 'onConfirmIdentiyAuthFailed',
    });
    const { onAction: trackOnSuccess } = useAnalyticsActionTracker('onEnterSmsCodeSuccess');

    const redirectUrl = getAbsoluteUrlWithParams(hookPageRedirectionPath());

    const handleSubmit = (formValues: { smsToken: '' }): void => {
        setConfirmAuthenticationCodeRequest({ isLoading: true });
        CpDataApi.post<ValidateTanStatus>(getVerifyCodeEndpoint(), {
            smsToken: formValues.smsToken.trim(),
            encryptedCustomerId: customerId,
        })
            .then(() => {
                trackOnSuccess();
                const hashedUserId = getHashedUserId(auth);
                resetHookPageStorage(hashedUserId);
                window.localStorage.setItem(acceptedConsentDateStorageKey + hashedUserId, consentValidDate);
                window.location.assign(redirectUrl);
            })
            .catch((error) => {
                setConfirmAuthenticationCodeRequest({
                    isLoading: false,
                    errorTranslationLabel: getConfirmAuthenticationCodeErrorTranslationLabel(
                        parseErrorResponse<ConfirmAuthorizationError>(error).code,
                    ),
                });
                trackOnError('SMS Identification');
            });
    };

    const handleResendSmsCode = (): void => {
        setResendSmsRequest({ isLoading: true });

        CpDataApi.post<void>(getRegistrationPersonEndpoint(), formValuesForStep1)
            .then(() => {
                setResendSmsRequest({ isLoading: false });
            })
            .catch((smsTokenError) => {
                setResendSmsRequest({
                    isLoading: false,
                    errorTranslationLabel: getResendSmsErrorTranslationLabel(
                        parseErrorResponse<SendAuthorizationCodeError>(smsTokenError).code,
                    ),
                });
            });
    };

    return (
        <>
            <Layout.Item className="u-text-center">
                <div dangerouslySetInnerHTML={{ __html: t('sms-identification.info-text', { phoneNumberEnding }) }} />
            </Layout.Item>
            <Layout.Item>
                <Formik
                    initialValues={{ smsToken: '' }}
                    validationSchema={smsIdentificationValidationSchema(t)}
                    onSubmit={handleSubmit}
                >
                    {(formik): JSX.Element => (
                        <Form onSubmit={preventSubmit()}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            delimiter: '',
                                            blocks: [6],
                                            numericOnly: true,
                                        }}
                                        inputMode={'numeric'}
                                        label={t('sms-identification.authorization-code.label')}
                                        name={'smsToken'}
                                        testId={testIds.smsToken}
                                    />
                                    {confirmAuthenticationCodeRequest.errorTranslationLabel && (
                                        <Fieldset.Row>
                                            <Notification
                                                className="u-mt"
                                                status={NotificationStatus.error}
                                                text={t(confirmAuthenticationCodeRequest.errorTranslationLabel)}
                                            />
                                        </Fieldset.Row>
                                    )}
                                </Fieldset.Row>

                                {resendSmsRequest.errorTranslationLabel && (
                                    <Fieldset.Row>
                                        <Notification
                                            status={NotificationStatus.error}
                                            text={t(resendSmsRequest.errorTranslationLabel)}
                                        />
                                    </Fieldset.Row>
                                )}
                                <Fieldset.Row>
                                    <Button full={true} onClick={formik.submitForm} testId={testIds.submit}>
                                        {t('sms-identification.submit')}
                                    </Button>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <p>
                                        {t('sms-identification.resend-code-text1')}
                                        <Button link onClick={handleResendSmsCode}>
                                            {t('sms-identification.resend-code-link')}
                                        </Button>
                                        {t('sms-identification.resend-code-text2')}
                                    </p>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
            {(resendSmsRequest.isLoading || confirmAuthenticationCodeRequest.isLoading) && <Spinner fullPage={true} />}
            {resendSmsRequest.isLoading && <Spinner fullPage={true} />}
        </>
    );
};
