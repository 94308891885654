import React, { useState } from 'react';
import { CpDataApi } from '../../../cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { Layout, Link, Modal } from '@vwfs-its/bronson-react';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared/frontend-ui';
import { getAmortizationTablePdfDownloadEndpoint } from '@cp-cz/common';
import { formatCpDate } from '@cp-shared/common-utilities';

type AmortizationTableDownloadProps = {
    contractNumber?: string;
    encryptedContractNumber?: string;
};
export const AmortizationTableDownload: React.FC<AmortizationTableDownloadProps> = ({
    contractNumber,
    encryptedContractNumber,
}) => {
    const { t } = useTranslation('amortization-details');
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);

    const { onAction: onDownload } = useAnalyticsActionTracker('onAmortizationTableDownload');

    if (!encryptedContractNumber || !contractNumber) return null;

    const download = () => {
        setIsDownloading(true);
        CpDataApi.get(getAmortizationTablePdfDownloadEndpoint(encryptedContractNumber))
            .then((response) => {
                onDownload();
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                const filename = `${t('download.filename')}_${contractNumber || ''}_${formatCpDate().format(
                    'YYYYMMDD',
                )}.pdf`;
                downloadFileAs(pdfBlob, filename);
                setIsDownloading(false);
            })
            .catch(() => {
                setDownloadError(true);
                setIsDownloading(false);
            });
    };

    return (
        <>
            <Layout.Item default="1/1" className="u-mt-large">
                {isDownloading ? (
                    <Spinner small center={false} />
                ) : (
                    <Link onClick={download} testId={'pdf-download-link'} normalLink icon="download">
                        {t('download.button')}
                    </Link>
                )}
            </Layout.Item>

            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('download.error-modal.close')}
                onConfirm={(): void => setDownloadError(false)}
                onClose={(): void => setDownloadError(false)}
                title={t('download.error-modal.title')}
                status="error"
                testId={'download-failure-modal'}
            >
                {t('download.error-modal.text')}
            </Modal>
        </>
    );
};
