export interface Registration {
    contractId: string;
    registrationPlateCode: string;
}

export interface VerificationResult {
    phoneNumber: string;
    customerNumber: string;
}

export interface RegistrationResult {
    phoneNumberEnding: string;
    customerId: string;
}

export type SendAuthorizationCodeError = 'INVALID_PHONE_NUMBER' | 'SMS_SENDING_FAILED' | 'CUSTOMER_ID_MISMATCH';
export type ConfirmAuthorizationError = 'INVALID_CODE';

export type RegistrationErrorType =
    | 'CONTRACT_NOT_ALLOWED'
    | 'IDENTITY_DOES_NOT_MATCH'
    | 'PRODUCT_NOT_SUPPORTED'
    | 'CUSTOMER_ALREADY_REGISTERED'
    | 'REQUEST_TIMEOUT'
    | 'TOO_MANY_REQUESTS'
    | 'MARKET_API_ERROR'
    | 'UNEXPECTED_BEHAVIOUR';

export enum RegistrationError {
    CONTRACT_NOT_ALLOWED = '405_METHOD_NOT_ALLOWED',
    IDENTITY_DOES_NOT_MATCH = '404_NOT_FOUND',
    PRODUCT_NOT_SUPPORTED = '406_NOT_ACCEPTABLE',
    CUSTOMER_ALREADY_REGISTERED = 'CUSTOMER_ALREADY_REGISTERED',
    REQUEST_TIMEOUT = '408_REQUEST_TIMEOUT',
    TOO_MANY_REQUESTS = '429_TOO_MANY_REQUESTS',
    NO_PHONE_NUMBER = '412_PRECONDITION_FAILED',
    MARKET_API_ERROR = 'MARKET_API_NOT_REACHABLE',
    UNEXPECTED_BEHAVIOUR = 'UNEXPECTED_BEHAVIOUR',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}
