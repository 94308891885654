import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableContent, FormSectionGroup } from '@vwfs-its/bronson-react';
import { Contract } from '@cp-cz/common';
import { ContractComponent } from '../contract';
import { Notification } from '@cp-shared/frontend-ui';
import { changeContactDetailsPath } from '../../navigation/paths';

export const ContractsUi: React.FC<{ contracts?: Contract[]; phoneNumber?: string }> = ({
    contracts = [],
    phoneNumber,
}) => {
    const { t } = useTranslation('contracts');

    const getListContracts = useCallback(
        (isActive: boolean) => {
            const filteredByStatus = (contracts || []).filter((contract) => contract.isActive === isActive);
            return (
                !!filteredByStatus.length && (
                    <FormSectionGroup>
                        {filteredByStatus.map((contract, index) => {
                            const defaultExpanded = index === 0;
                            return (
                                <ContractComponent
                                    key={contract.contractNumber}
                                    contract={contract}
                                    defaultExpanded={isActive && defaultExpanded}
                                />
                            );
                        })}
                    </FormSectionGroup>
                )
            );
        },
        [contracts],
    );

    const activeContracts = useMemo(() => getListContracts(true), [getListContracts]);
    const inactiveContracts = useMemo(() => getListContracts(false), [getListContracts]);

    if (!contracts) {
        return null;
    }

    return (
        <>
            {!activeContracts && (
                <Notification
                    headlineIcon={'control-info-circle'}
                    custom
                    text={t('notification.no-active-contracts')}
                    testId={'no-active-contracts-info'}
                    className={!phoneNumber ? 'u-mb' : ''}
                />
            )}
            {!phoneNumber && (
                <Notification
                    headlineIcon={'control-info-circle'}
                    custom
                    text={
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('notification.missing-client-phone-number', {
                                    editContactDetailsUrl: changeContactDetailsPath(),
                                }),
                            }}
                        />
                    }
                    testId={'missing-client-phone-number-notification'}
                    className={'u-mb'}
                />
            )}
            {activeContracts}
            {inactiveContracts && (
                <div className={'u-mt-large'}>
                    <ExpandableContent
                        lazyRender
                        testId="inactive-contracts-expandable"
                        className={'u-mt'}
                        noBackground
                        pageWrapClassName={'u-pl-none u-pr-none'}
                        triggerLabel={t('expand.trigger')}
                    >
                        <h4 className="u-text-center">{t('expand.headline')}</h4>
                        {inactiveContracts}
                    </ExpandableContent>
                </div>
            )}
        </>
    );
};
