import { Contract } from '@cp-cz/common';
import { MyDocumentsLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared/frontend-ui';
import { useContracts } from 'components/contracts';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile/useMyProfile';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MyDocumentsUi } from './ui';
import { useMyDocuments } from './useMyDocuments';
import {
    getCopyOfRegistrationCertificate,
    getDriverSetDocument,
    getGeneralTermsDocuments,
    getGreenCardDocuments,
} from './utils';

export const MyDocuments: React.FC = () => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingErrorContracts } = useContracts();
    const { data: myProfileData, isLoading: isLoadingMyProfile, loadingError: loadingErrorMyProfile } = useMyProfile();
    const { data: documents, isLoading: isLoadingDocuments } = useMyDocuments();

    const isLoading: boolean = isLoadingContracts || isLoadingMyProfile || isLoadingDocuments;
    const loadingError: boolean = !!loadingErrorContracts || !!loadingErrorMyProfile;

    const { t } = useTranslation(['my-documents', 'contracts']);
    useAnalyticsPageViewTracker('postbox', !loadingError);

    const mapGeneralTermsAndDriverSetDocuments = (contracts: Contract[], t: TFunction) => {
        const generalTermsDocuments = getGeneralTermsDocuments(contracts, t);
        const driverSetDocument = getDriverSetDocument(contracts, t, myProfileData);
        const registrationCertificateDocument = getCopyOfRegistrationCertificate(
            contracts,
            t,
            documents?.copyOfRegistrationCertificates,
        );
        const greenCardDocuments = getGreenCardDocuments(contracts, t, myProfileData);
        return generalTermsDocuments
            .concat(driverSetDocument)
            .concat(registrationCertificateDocument)
            .concat(greenCardDocuments);
    };

    const MyDocumentWithHandlers = withLoadingAndNoConnectionHandler(MyDocumentsUi);
    const myDocuments = contracts ? mapGeneralTermsAndDriverSetDocuments(contracts, t) : [];

    return (
        <MyDocumentWithHandlers
            myDocuments={myDocuments}
            isLoading={isLoading}
            hasError={loadingError}
            loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
        />
    );
};
