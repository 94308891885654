import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    landingPagePath,
    loginRedirectPagePath,
    legalNoticePath,
    myDocumentsPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    registrationPagePath,
    thirdPartyLicensesPagePath,
    contactDetailsHookPagePath,
    marketingPreferencesHookPagePath,
    hookPageRedirectionPath,
    consentHookPagePath,
    vwfsVerificationPagePath,
} from './paths';
import { getCurrentPageName } from '../../utils';

const pagesWithoutNavbar = [
    registrationPagePath(),
    vwfsVerificationPagePath(),
    loginRedirectPagePath(),
    contactDetailsHookPagePath(),
    marketingPreferencesHookPagePath(),
    consentHookPagePath(),
    hookPageRedirectionPath(),
];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    registrationPagePath(),
    vwfsVerificationPagePath(),
    thirdPartyLicensesPagePath(),
];

function publicNavigationLinks(t: TFunction, onLogin: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.login'),
            onClick: onLogin,
        },
    ];
}

const publicNavigationErrorPagesLinks = (t: TFunction, onLogin: () => Promise<void>): SiteNavItemPropsList => {
    return [
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.error-page-login'),
            onClick: onLogin,
        },
    ];
};

function privateNavigationLinks(t: TFunction, onLogout: () => void): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: myDocumentsPagePath(),
            label: t('navigation.documents'),
        },
        {
            url: myRequestsPagePath(),
            label: t('navigation.request'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: onLogout,
        },
    ];
}

export const NavigationBar: React.FC = () => {
    const { t } = useTranslation('navigation');
    const history = useHistory();
    const { isAuthenticated, logout } = useAuthentication();
    const location = useLocation();
    const currentPageName = getCurrentPageName(location);

    const login = useLogin();
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');

    const ErrorPagesNames = ['Not found', 'Not authorized', 'Forbidden'];
    const licencesPage = 'licenses';

    const navigationItems = (
        isAuthenticated
            ? privateNavigationLinks(t, () => {
                  onLogoutAction(currentPageName);
                  return logout({ redirectUri: window.location.origin + landingPagePath() });
              })
            : ErrorPagesNames.includes(currentPageName)
            ? publicNavigationErrorPagesLinks(t, login)
            : publicNavigationLinks(t, login)
    ).map((navItem) => ({
        ...{ isActive: history.location.pathname === navItem.url },
        ...navItem,
    }));

    const currentPathName = history.location.pathname;

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };

    const logoRedirectPath = isAuthenticated
        ? licencesPage.includes(location.pathname.split('/')[1])
            ? landingPagePath()
            : dashboardPagePath()
        : landingPagePath();

    return (
        <NavigationBarShared
            logoAltText={t(`navigation.${isAuthenticated ? 'dashboard' : 'home'}`)}
            onLogoClickPath={logoRedirectPath}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
