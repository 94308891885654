import { countryCodes } from '@cp-cz/common';
import { ValidatedCustomSelect } from '@cp-shared/frontend-ui';
import { FormField } from '@vwfs-its/bronson-react';
import React from 'react';

export const CountryCodeSelection: React.FC<{
    name: string;
    testId: string;
    defaultPrefix?: string;
    handleChange?: () => void;
}> = ({ name, testId, defaultPrefix, handleChange }) => {
    const defaultPrefixWithoutPlus = defaultPrefix
        ? defaultPrefix[0] === '+'
            ? defaultPrefix?.substring(1)
            : defaultPrefix
        : '';

    const options = countryCodes.map(({ dialCode, name, code }: { dialCode: string; name: string; code: string }) => {
        return {
            optionKey: code,
            optionValue: `+${dialCode}`,
            content: <>{`${name} +${dialCode}`}</>,
            testId: `contract-selection-item-${dialCode}`,
        };
    });

    return (
        <FormField type="select" id={'country-code-selection'}>
            <ValidatedCustomSelect
                testId={`${testId}prefix`}
                icon="semantic-expand"
                defaultOptionKey={countryCodes.find((x) => x.dialCode === defaultPrefixWithoutPlus)?.code}
                name={name}
                customSelectItems={options}
                onChange={handleChange}
            />
        </FormField>
    );
};
