import React from 'react';
import { Card } from '@vwfs-its/bronson-react';
import { Contract, ProductTypeLevel3 } from '@cp-cz/common';
import { LicensePlate } from '../../license-plate';
import { ContractSummaryItems } from './contract-sumary-items';
import { useTranslation } from 'react-i18next';
import { ContractBody } from './contract-body';
import { HolidayNotification } from './holiday-notification';
import { DebtNotification } from './debt-notification';

type ContractComponentProps = {
    contract: Contract;
    defaultExpanded: boolean;
};

export const ContractComponent: React.FC<ContractComponentProps> = ({ contract, defaultExpanded }) => {
    const { t } = useTranslation('contracts');
    /*
    removed  details: { financial } because market decided to hide payment holiday notification
    */
    //const {contractNumber, productTypeLevel3, details: { financial }} = contract;
    const { contractNumber, productTypeLevel3 } = contract;
    /*
    change flag to false and  the notification is not shown
    because market has decided that
    another field on FT side is missing
    to reliably display this to relevant users.
    */
    //const paymentHolidayActiveFlag = financial?.paymentHolidayActiveFlag;
    const paymentHolidayActiveFlag = false;

    const holidayNotificationProductTypes = [
        ProductTypeLevel3.ICVBW,
        ProductTypeLevel3.ICVBWO,
        ProductTypeLevel3.OL,
        ProductTypeLevel3.FL,
    ];

    const { model = '', brand = '', licensePlate = '', fuelTypeCode, vehicleType } = contract.details.vehicle || {};
    const getFuelTypeText = !!fuelTypeCode ? `(${t(`fuel-type.${fuelTypeCode}`)})` : '';
    const getContractNumberText = !!contractNumber ? `${t('contract-number')}${contractNumber}` : '';

    const title = `${brand} ${model} ${vehicleType} ${getFuelTypeText}`.trim();
    const subtitle = (
        <span
            dangerouslySetInnerHTML={{
                __html: `<span>${getContractNumberText}</span></br><span>${
                    !!productTypeLevel3 ? t(`product-type-level-3.${productTypeLevel3}`) : ''
                }</span>`,
            }}
        />
    );

    const licensePlateComponent = <LicensePlate registrationNumber={licensePlate.toUpperCase()} />;

    return (
        <Card
            element="article"
            expandable
            title={title}
            subtitle={subtitle}
            contentShort={licensePlateComponent}
            defaultExpanded={defaultExpanded}
            className={'u-mb'}
            contentLarge={<ContractBody contract={contract} />}
        >
            <ContractSummaryItems contract={contract} />
            {paymentHolidayActiveFlag &&
                productTypeLevel3 &&
                holidayNotificationProductTypes.includes(productTypeLevel3) && (
                    <HolidayNotification contract={contract} />
                )}

            <DebtNotification contract={contract} />
        </Card>
    );
};
