import { Greeting } from '@cp-cz/common';

export const GreetingForPrivateCustomer: Greeting = {
    message: 'Vitajte pán Frátrič',
    name: 'pán Frátrič',
};

export const GreetingForBusinessCustomer: Greeting = {
    message: 'Vitajte ABS s.r.o.',
    name: 'ABS s.r.o.',
};

export const EmptyGreetingMessage: Greeting = {
    message: '',
    name: '',
};
