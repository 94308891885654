import { FuelTypeCode, VehicleDetails } from '@cp-cz/common';
import { formatCpDate } from '@cp-shared/common-utilities';

export const VehicleDetailsWithAllValues: VehicleDetails = {
    vin: 'WAUZZZ4G1FN076523',
    licensePlate: '4A2 3000',
    logBookID: 'Registration certificate',
    depositedVWFSFlag: 'Y',
    logBookOwnerName: 'Owner Name',
    registrationDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
    stkDate: formatCpDate('2017-11-23T09:44:57.299').toCpDate(),
    contractualMileageTotalKM: 127000,
    contractualMileageToleranceKM: 15000,
    brand: 'Volkswagen',
    model: 'Golf',
    vehicleType: '2.0. TDI',
    fuelTypeCode: FuelTypeCode.PET,
};

export const VehicleDetailsWithAllValuesAndNoDeposite: VehicleDetails = {
    vin: 'WAUZZZ4G1FN076523',
    licensePlate: '2H2 7149',
    logBookID: 'Registration certificate',
    depositedVWFSFlag: 'N',
    logBookOwnerName: 'Owner Name',
    registrationDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
    stkDate: formatCpDate('2017-11-23T09:44:57.299').toCpDate(),
    contractualMileageTotalKM: 127000,
    contractualMileageToleranceKM: 15000,
    brand: 'Volkswagen',
    model: 'Golf',
    vehicleType: '2.0. TDI',
    fuelTypeCode: FuelTypeCode.PET,
};

export const VehicleDetailsWithMissingValues: VehicleDetails = {
    vin: 'WAUZZZ4G1FN076523',
    registrationDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
};

export const VehicleDetailsForSkoda: VehicleDetails = {
    vin: 'WAUZZZ4G1FN076523',
    licensePlate: '2H2 7149',
    logBookID: 'Registration certificate',
    depositedVWFSFlag: 'N',
    logBookOwnerName: 'Owner Name',
    registrationDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
    stkDate: formatCpDate('2017-11-23T09:44:57.299').toCpDate(),
    contractualMileageTotalKM: 127000,
    contractualMileageToleranceKM: 15000,
    brand: 'Skoda',
    model: 'Fabia',
    vehicleType: '2.0. TDI',
    fuelTypeCode: FuelTypeCode.PET,
};
