import { HeroImage, useAnalyticsErrorPageTracker, StaticPageLoadingPlaceholder } from '@cp-shared/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';

import { useForbiddenErrorPage } from './useForbiddenErrorPage';

export const ForbiddenErrorPage: React.FC = () => {
    const { cmsContent: forbiddenErrorContent, isLoading, loadingError } = useForbiddenErrorPage();

    const login = useLogin();
    const onClick = () => {
        login();
    };

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('accessDeniedError', !!forbiddenErrorContent);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={forbiddenErrorContent?.pretitle}
            title={forbiddenErrorContent?.title}
            subTitle={forbiddenErrorContent?.subTitle}
            buttonText={forbiddenErrorContent?.buttonText}
            clickHandler={onClick}
            inverted
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
        />
    );
};
