import { ThirdPartyLicenses, getThirdPartyLicensesEndpoint } from '@cp-cz/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ThirdPartyLicenses>({
    contentName: 'thirdPartyLicenses',
    contentEndpoint: getThirdPartyLicensesEndpoint,
});

export default reducer;
export const fetchThirdPartyLicenses = fetchContent;
