import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const smsIdentificationValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
        smsToken: Yup.string()
            .trim()
            .required(t('sms-identification.authorization-code.error.missing'))
            .length(6, t('sms-identification.authorization-code.error.invalid')),
    });
};
