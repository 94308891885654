import React from 'react';
import { ConsentHookpage } from '@cp-cz/common';
import { Button, ButtonContainer } from '@vwfs-its/bronson-react';
import { CommonUserDetailsComponent } from '../../common-hookpage-component/CommonUserDetailsComponent';
import { useTranslation } from 'react-i18next';

type ConsentHookpageComponentUiProps = {
    consentHookpageContent?: ConsentHookpage;
    handleAcceptConsent: () => void;
    handleRejectConsent: () => void;
};

export const ConsentHookpageComponentUi: React.FC<ConsentHookpageComponentUiProps> = ({
    consentHookpageContent,
    handleAcceptConsent,
    handleRejectConsent,
}) => {
    const { t } = useTranslation('terms-and-conditions-hookpage');

    if (!consentHookpageContent) return null;

    const { title, text } = consentHookpageContent;

    return (
        <CommonUserDetailsComponent title={title}>
            <div className="u-text-center" dangerouslySetInnerHTML={{ __html: text }} />
            <ButtonContainer center className={'u-mt'}>
                <Button secondary onClick={() => handleRejectConsent()} testId="reject-button">
                    {t('button.reject')}
                </Button>
                <Button onClick={() => handleAcceptConsent()} testId="confirm-button">
                    {t('button.confirm')}
                </Button>
            </ButtonContainer>
        </CommonUserDetailsComponent>
    );
};
