import { ErrorPage, getForbiddenErrorEndpoint } from '@cp-cz/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ErrorPage>({
    contentName: 'forbiddenError',
    contentEndpoint: getForbiddenErrorEndpoint,
});

export default reducer;
export const fetchForbiddenError = fetchContent;
