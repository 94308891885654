import React from 'react';
import { FinancialDetails, InsuranceCoverage } from '@cp-cz/common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

type CollisionDemageSectionProps = {
    coverage: InsuranceCoverage;
    financialDetails?: FinancialDetails;
};

export const CollisionDemageSection: React.FC<CollisionDemageSectionProps> = ({ coverage, financialDetails }) => {
    const { t, f } = useTranslationWithFormatting('insurance-details');
    const translationPrefix = 'collision-demage-section';
    const { currencyCode } = financialDetails || {};

    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;

    const {
        coverageAmt,
        deductibleRate,
        deductibleAmt,
        deductibleHomeServiceRate,
        deductibleHomeServiceAmt,
        deductibleOutsideHomeServiceRate,
        deductibleOutsideHomeServiceAmt,
    } = coverage;

    if (
        !coverageAmt &&
        !deductibleRate &&
        !deductibleAmt &&
        !deductibleHomeServiceRate &&
        !deductibleHomeServiceAmt &&
        !deductibleOutsideHomeServiceRate &&
        !deductibleOutsideHomeServiceAmt
    )
        return null;

    const getDeductibleValue = (rate?: number, amount?: number) =>
        rate && amount
            ? t(`${translationPrefix}.deductibe-rate-value`, {
                  rate: f(rate, TranslationFormat.NUMBER),
                  amount: f(amount, TranslationFormat.CURRENCY, currencyCodeTranslation),
              })
            : '-';

    const itemList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.coverage-amount`),
            value: coverageAmt ? f(coverageAmt, TranslationFormat.CURRENCY, currencyCodeTranslation) : '-',
            testId: 'coverage-amount',
        },
        {
            label: t(`${translationPrefix}.deductibe-rate-and-amount`),
            value: getDeductibleValue(deductibleRate, deductibleAmt),
            testId: 'deductibe-rate-and-amount',
        },
        {
            label: t(`${translationPrefix}.deductibe-home-service`),
            value: getDeductibleValue(deductibleHomeServiceRate, deductibleHomeServiceAmt),
            testId: 'deductibe-home-service',
        },
        {
            label: t(`${translationPrefix}.deductibe-outside-home-service`),
            value: getDeductibleValue(deductibleOutsideHomeServiceRate, deductibleOutsideHomeServiceAmt),
            testId: 'deductibe-outside-home-service',
        },
    ];

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)} withoutCardEffect>
            <DefinitionListHorizontal list={itemList} />
        </DataOverview>
    );
};
