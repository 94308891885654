import { CPDate } from '@cp-shared/common-utilities';

export enum CoverageCode {
    POV = 'POV',
    HAV = 'HAV',
    NAV = 'NAV',
    SKL = 'SKL',
}

export enum CoverageDesc {
    PROPERTY_DEMAGE = 'Povinné ručení',
    COLLISION_DEMAGE = 'Havarijní pojištění',
    REPLACEMENT_VEHICLE = 'Náhradní vozidlo',
    WINDSHIELD_COVERAGE = 'Pojištění skla/skel',
}

export type InsuranceCoverage = {
    coverageCode?: CoverageCode | string;
    coverageDesc?: CoverageDesc | string;
    coverageEndDate?: CPDate;
    coverageExpectedEndDate?: CPDate;
    limitHealthAmt?: number;
    limitPropertyAmt?: number;
    coverageAmt?: number;
    deductibleRate?: number;
    deductibleAmt?: number;
    deductibleHomeServiceRate?: number;
    deductibleHomeServiceAmt?: number;
    deductibleOutsideHomeServiceRate?: number;
    deductibleOutsideHomeServiceAmt?: number;
    coverageWindowVariantCode?: string;
    replacementVehicleDays?: string;
};

export type Insurance = {
    contractInsuranceNumber?: string;
    insuranceStartDate?: CPDate;
    insuranceCompanyName?: string;
    insuranceCompanyCode?: string;
    brandedProductFlag?: boolean;
    recordStateCode?: string;
    coverages: InsuranceCoverage[];
};
