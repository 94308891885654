import { MarketingPreferencesError } from '../types';

export const defaultValidationMarketingPreferencesErrorMessages: MarketingPreferencesError = {
    directMarketing: {
        required: 'Please choose one option.',
    },
    indirectMarketing: {
        required: 'Please choose one option.',
    },
};
