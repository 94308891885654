import { AmortizationDetails, getPaymentsEndpoint } from '@cp-cz/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared/common-utilities';

import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared/frontend-integration';
import { fetchPayments } from './PaymentsSlice';
import { PaymentsDataSelector } from './PaymentsDataSelector';

export function usePayments(
    encryptedContractNumber: string,
): AbstractDataState<AmortizationDetails, DefaultBusinessMarketApiErrorCode> {
    return useGetContractBasedApiData(
        encryptedContractNumber,
        fetchPayments,
        PaymentsDataSelector,
        getPaymentsEndpoint(encryptedContractNumber),
        false,
    );
}
