import { Spinner, useAnalyticsPageViewTracker, useAuthentication } from '@cp-shared/frontend-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useUpdateCustomerType } from '../customer-type/useUpdateCustomerType';
import { isAfterLoginLocalStorageKey } from 'config';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ContentSection, Layout, Logo, PageWrap } from '@vwfs-its/bronson-react';
import {
    bankIDVerificationPagePath,
    hookPageRedirectionPath,
    registrationPagePath,
    vwfsVerificationPagePath,
} from '../navigation/paths';
import { VWFSVerification } from './vwfs-verification';
import { RegistrationUi } from './ui';
import { CpDataApi } from '../../cp-xhr';
import {
    getRegistrationBankIDEndpoint,
    getRegistrationBankIDVerifyEndpoint,
    RegistrationBankIDErrorCode,
    RegistrationBankIDResponse,
} from '@cp-cz/common';
import { getAbsoluteUrl, getUrlSearchParams } from '../../utils/urlSearchParams';
import { getBankIDParams, getBankIDVerifyErrorMessage } from './helpers';
import { resetHookPageStorage } from 'utils';
import { getHashedUserId } from '../hook-page-redirection/utils';

export const Registration: React.FC = () => {
    const auth = useAuthentication();
    const { path } = useRouteMatch();
    const { customerTypeData } = useUpdateCustomerType();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const history = useHistory();
    const location = useLocation();

    useAnalyticsPageViewTracker('confirmIdentity', !customerTypeData);
    window.localStorage.removeItem(isAfterLoginLocalStorageKey);

    const scrollToTopRef = useRef<null | HTMLDivElement>(null);
    const executeScrollToTop = () => scrollToTopRef.current?.scrollIntoView();

    useEffect(() => {
        const params = getUrlSearchParams();
        const errorCodeParam = params.get('errorCode');
        if (!!errorCodeParam) {
            history.push({
                pathname: registrationPagePath(),
                state: { errorMessage: 'verification-failed' },
            });
        } else if (location.pathname === bankIDVerificationPagePath()) {
            setIsLoading(true);
            const bankIDParams = getBankIDParams(params);
            CpDataApi.post(getRegistrationBankIDVerifyEndpoint(), bankIDParams)
                .then(() => {
                    const hashedUserId = getHashedUserId(auth);
                    resetHookPageStorage(hashedUserId);
                    const redirectUrl = getAbsoluteUrl(hookPageRedirectionPath());
                    window.location.assign(redirectUrl);
                })
                .catch(({ response }) => {
                    const bankIDVerifyErrorMessage = getBankIDVerifyErrorMessage(response.data.code);
                    setIsLoading(false);
                    history.push({
                        pathname: registrationPagePath(),
                        state: { errorMessage: bankIDVerifyErrorMessage },
                    });
                });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        const bankIdError = (location.state as { errorMessage?: string })?.errorMessage;
        if (!!bankIdError) {
            setErrorMessage(bankIdError);
            executeScrollToTop();
        }
    }, [location.state]);

    const handleBankIdRegistration = () => {
        setErrorMessage(undefined);
        setIsLoading(true);
        const portalBaseUrl = getAbsoluteUrl(bankIDVerificationPagePath());
        CpDataApi.post<RegistrationBankIDResponse>(getRegistrationBankIDEndpoint(), { portalBaseUrl })
            .then((response) => {
                const bankIDUrl = response.data.url;
                window.location.assign(bankIDUrl);
            })
            .catch(({ response }) => {
                response.data.code === RegistrationBankIDErrorCode.CUSTOMER_ALREADY_REGISTERED
                    ? setErrorMessage('customer-already-registered')
                    : setErrorMessage('verification-failed');
                setIsLoading(false);
                executeScrollToTop();
            });
    };

    return (
        <ContentSection>
            <div ref={scrollToTopRef}>
                {isLoading && <Spinner fullPage={true} />}
                <PageWrap size={'xsmall'}>
                    <Layout>
                        <Layout.Item default={'1/1'}>
                            <Logo className={'u-block-center'} />
                        </Layout.Item>
                        <Switch>
                            <Route exact path={vwfsVerificationPagePath()}>
                                <VWFSVerification />
                            </Route>
                            <Route path={path}>
                                <RegistrationUi
                                    clearError={() => {
                                        setErrorMessage(undefined);
                                    }}
                                    handleBankIdRegistrationClick={handleBankIdRegistration}
                                    errorMessageKey={errorMessage}
                                />
                            </Route>
                        </Switch>
                    </Layout>
                </PageWrap>
            </div>
        </ContentSection>
    );
};
