import * as Yup from 'yup';
import { AddressValidatorErrorMessagesType } from '../types';
import { defaultAddressValidationErrorMessages } from '../messages';
import { AddressChangeRequest, AddressType } from '../../apis';
import { Base64File } from '@cp-shared/apis';

const regexRules = {
    zipCode: /^[1-7][0-9]*$/,
};

export const validAddressFileFormats = ['pdf', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'docx', 'doc'];

export const maxAddressFileSize = 4 * 1024 * 1024;

const isSingleFileWeightInLimit = (files: (File | Base64File)[]): boolean =>
    !files?.length || files.every((file: File | Base64File) => maxAddressFileSize > file.size);

const areFilesInCorrectType = (files: (File | Base64File)[]): boolean =>
    !files?.length ||
    files.every((file: File | Base64File): boolean | void => {
        let ext: string[];
        if ('name' in file) {
            ext = file.name.split('.');
            return validAddressFileFormats.includes(ext[ext.length - 1]);
        }
        if ('filename' in file) {
            ext = file.filename.split('.');
            return validAddressFileFormats.includes(ext[ext.length - 1]);
        }
    });

export const addressValidationSchema = (
    isMainAddress = false,
    isPOCustomer = false,
    errorMessages: AddressValidatorErrorMessagesType = defaultAddressValidationErrorMessages,
): Yup.SchemaOf<AddressChangeRequest | Omit<AddressChangeRequest, 'files'>> => {
    let basicSchema = Yup.object().shape({
        customerType: Yup.mixed().oneOf(['NATURAL_PERSON', 'LEGAL_PERSON', 'NATURAL_PERSON_WITH_LEGAL_PERSONALITY']),
        addressType: Yup.mixed().oneOf([AddressType.MAIN, AddressType.CONTACT]),
        country: Yup.string()
            .trim()
            .required(errorMessages.country.required)
            .when('addressType', { is: AddressType.MAIN, then: Yup.string().oneOf(['Česko']) }),
        street: Yup.string().trim().max(50, errorMessages.street.valid),
        zipCode: Yup.string()
            .trim()
            .required(errorMessages.zipCode.required)
            .when('country', {
                is: 'Česko',
                then: Yup.string()
                    .length(5, errorMessages.zipCode.valid)
                    .matches(regexRules.zipCode, errorMessages.zipCode.valid),
            }),

        city: Yup.string().trim().required(errorMessages.city.required).max(50, errorMessages.city.valid),
        streetNumber: Yup.string()
            .trim()
            .required(errorMessages.streetNumber.required)
            .max(10, errorMessages.streetNumber.valid),
    });

    const filesBasicSchema = Yup.array()
        .max(1, errorMessages.files.maxFiles)
        .test('maxTotalSize', errorMessages.files.maxTotalSize, (files) =>
            isSingleFileWeightInLimit(files as (File | Base64File)[]),
        )
        .test('fileType', errorMessages.files.fileType, (files) =>
            areFilesInCorrectType(files as (File | Base64File)[]),
        );

    if (!isPOCustomer && isMainAddress) {
        basicSchema = basicSchema.shape({
            files: filesBasicSchema.min(1, errorMessages.files.required),
        });
    } else if (isMainAddress && isPOCustomer) {
        basicSchema = basicSchema.shape({
            files: filesBasicSchema,
        });
    }

    return basicSchema;
};
