import React from 'react';
import { Addresses, CustomerType, EditStatus } from '@cp-cz/common';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared/frontend-ui';
import { useTranslation } from 'react-i18next';
import { AddressList } from '../common/AddressList';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeMainAddressPath } from '../../../navigation/paths';
import { EditView } from '../common/edit-view/EditView';
import { AddressChangeForm } from '../common/edit-view/getSavedData';

type MainAddressProps = {
    addresses?: Addresses;
    customerType?: CustomerType;
    handleSubmit: (values: AddressChangeForm) => void;
    backToNonEditMode: () => void;
    setLastEditStatus: (editStatus: EditStatus) => void;
    lastEditStatus?: EditStatus;
};

export const MainAddress: React.FC<MainAddressProps> = ({
    addresses,
    customerType,
    handleSubmit,
    backToNonEditMode,
    setLastEditStatus,
    lastEditStatus,
}) => {
    const { t } = useTranslation('my-profile');
    const history = useHistory();
    const { path } = useRouteMatch();

    if (!addresses || !addresses.mainAddress || !customerType) {
        return null;
    }

    const startEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeMainAddressPath());
    };

    const status = lastEditStatus === EditStatus.SUCCESS ? 'success' : 'error';

    return (
        <Switch>
            <Route exact path={changeMainAddressPath()}>
                <EditView
                    onCancel={backToNonEditMode}
                    addresses={addresses}
                    customerType={customerType}
                    handleSubmit={handleSubmit}
                    isMainAddress={true}
                />
            </Route>
            <Route path={path}>
                <DataOverview
                    title={
                        customerType === 'LEGAL_PERSON'
                            ? t('addresses-section.headquarter-address')
                            : t('addresses-section.main-address.title')
                    }
                    withoutCardEffect={true}
                    className={'u-pt-none'}
                    testId="main-address-overview"
                    buttonLabel={t('addresses-section.main-address.edit-button')}
                    buttonProps={{ onClick: startEditing }}
                >
                    <AddressList address={addresses.mainAddress} />
                    {lastEditStatus !== EditStatus.NOT_PERFORMED && (
                        <Notification
                            status={NotificationStatus[status]}
                            headline={t(`contact-section.notification.${status}.title`)}
                            text={t(`contact-section.notification.${status}.description`)}
                        />
                    )}
                </DataOverview>
            </Route>
        </Switch>
    );
};
