import React, { useEffect, useState } from 'react';
import { Contract } from '@cp-cz/common';
import { useTranslation } from 'react-i18next';
import { CustomSelect, ErrorMessage, FormField, FormFieldLabel } from '@vwfs-its/bronson-react';
import { useField } from 'formik';
import { LicensePlate } from 'components/license-plate';
import { getContractByContractNumber } from '../utils';

export type ContractSelectionProps = {
    contracts?: Contract[];
    handleViewChange: (value: string) => void;
    hideEndOfTermCalculation?: boolean;
};

export const generalKey = 'generalKey';
export const devinculationKey = 'devinculationKey';
export const endOfTermCalculationKey = 'endOfTermCalculationKey';

export const ContractSelection: React.FC<ContractSelectionProps> = ({
    contracts,
    handleViewChange,
    hideEndOfTermCalculation,
}) => {
    const [field, meta, helpers] = useField('optionKey');
    const { t } = useTranslation('open-request');
    const generalOptionValue = t('form.form-fields.general-request');
    const devinculationOptionValue = t('form.form-fields.devinculation');
    const endOfTermCalculationOptionValue = t('form.form-fields.end-of-term-calculation');
    const [defaultOptionKey, setDefaultOptionKey] = useState(generalKey);

    useEffect(() => {
        if (!field.value) {
            helpers.setValue(generalKey);
            setDefaultOptionKey(generalKey);
        }
    }, [helpers, field.value]);

    const getContractNumberFromKey = (key: string): string => {
        if (key === devinculationKey || key === generalKey || key === endOfTermCalculationKey) {
            return key;
        }
        return getContractByContractNumber(contracts, key)?.contractNumber || '';
    };

    const setValueChange = (key: string) => {
        handleViewChange(key);
        const newKey = getContractNumberFromKey(key);
        setDefaultOptionKey(newKey);
        return helpers.setValue(newKey);
    };

    const contractOptions = (contracts || [])
        .filter((contract) => !!contract.contractNumber)
        .sort((a) => (a.isActive ? -1 : 1))
        .map(({ contractNumber, details, productTypeLevel3 }) => {
            return (
                <CustomSelect.Item
                    testId={`contract-selection-item-${contractNumber}`}
                    optionValue={contractNumber}
                    optionKey={contractNumber}
                    key={contractNumber}
                >
                    <LicensePlate className="u-text-base" registrationNumber={details.vehicle?.licensePlate} />
                    <br />
                    <strong>
                        {t('form.form-fields.contract-number')} {contractNumber}
                    </strong>
                    <br />
                    <b> {t(`contracts:product-type-level-3.${productTypeLevel3}`)} </b>
                    <br />
                    {details.vehicle?.brand} {details.vehicle?.model} {details.vehicle?.vehicleType}
                </CustomSelect.Item>
            );
        })
        .filter(Boolean);

    const generalOption = (
        <CustomSelect.Item
            testId={`contract-selection-item-general`}
            optionValue={generalOptionValue}
            optionKey={generalKey}
            key={generalKey}
        >
            <b>{generalOptionValue}</b>
        </CustomSelect.Item>
    );

    const devinculationOption = (
        <CustomSelect.Item
            testId={`contract-selection-item-devinculation`}
            optionValue={devinculationOptionValue}
            optionKey={devinculationKey}
            key={devinculationKey}
        >
            <b>{devinculationOptionValue}</b>
        </CustomSelect.Item>
    );

    const endOfTermCalculationOption = hideEndOfTermCalculation
        ? []
        : [
              <CustomSelect.Item
                  testId={'contract-selection-item-end-of-term-calculation'}
                  optionValue={endOfTermCalculationOptionValue}
                  optionKey={endOfTermCalculationKey}
                  key={endOfTermCalculationKey}
              >
                  <b>{endOfTermCalculationOptionValue}</b>
              </CustomSelect.Item>,
          ];

    const options = [generalOption, devinculationOption, ...endOfTermCalculationOption, ...contractOptions];
    const label = t('form.picklist');
    return (
        <>
            <FormFieldLabel>{label}</FormFieldLabel>
            <FormField
                type="select"
                testId={'contract-selection'}
                id={'contract-selection'}
                name="optionKey"
                errorMessage={
                    meta.touched &&
                    meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
                }
            >
                <CustomSelect
                    testId={'custom-contract-select'}
                    icon="semantic-expand"
                    onChange={setValueChange}
                    name="optionKey"
                    defaultOptionKey={defaultOptionKey}
                    key={defaultOptionKey}
                >
                    {options}
                </CustomSelect>
            </FormField>
        </>
    );
};
