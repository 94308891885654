import React from 'react';
import { VehicleDetails, ProductTypeLevel3 } from '@cp-cz/common';
import { GeneralSection } from '../general-section';

type VehicleDetailsUiProps = { vehicleDetails?: VehicleDetails; productTypeLevel3?: ProductTypeLevel3 };

export const VehicleDetailsUi: React.FC<VehicleDetailsUiProps> = ({ vehicleDetails, productTypeLevel3 }) => {
    if (!vehicleDetails || !productTypeLevel3) {
        return null;
    }

    return <GeneralSection vehicleDetails={vehicleDetails} productTypeLevel3={productTypeLevel3} />;
};
