import { MyProfileData } from '@cp-cz/common';
import { Address1WithAllValues, Address2WithAllValues } from './addresses-section/ExampleData';
import { ContactDetailsWithAllValues, ContactDetailsWithoutValues } from './contact-section/ExampleData';
import {
    NaturalPersonIdentificationWithAllValues,
    NaturalPersonWithLegalIdentificationWithAllValues,
    LegalPersonIdentificationWithAllValues,
    NaturalPersonIdentificationWithMissinglValues,
    NaturalPersonWithLegalIdentificationWithMissingValues,
    LegalPersonIdentificationWithMissingValues,
} from './identification-section/ExampleData';

//Natural person
export const NaturalPersonWithAllValues: MyProfileData = {
    identification: NaturalPersonIdentificationWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    addresses: {
        mainAddress: Address1WithAllValues,
        contactAddress: Address2WithAllValues,
    },
};

export const NaturalPersonWithMissingValues: MyProfileData = {
    identification: NaturalPersonIdentificationWithMissinglValues,
    contactDetails: ContactDetailsWithoutValues,
    addresses: {},
};

export const NaturalPersonWithoutContactValues: MyProfileData = {
    identification: NaturalPersonIdentificationWithAllValues,
    contactDetails: ContactDetailsWithoutValues,
    addresses: {
        mainAddress: Address1WithAllValues,
        contactAddress: Address2WithAllValues,
    },
};

//Natural person with legal
export const NaturalPersonWithLegalWithAllValues: MyProfileData = {
    identification: NaturalPersonWithLegalIdentificationWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    addresses: {
        mainAddress: Address1WithAllValues,
        contactAddress: Address2WithAllValues,
    },
};

export const NaturalPersonWithLegalWithMissingValues: MyProfileData = {
    identification: NaturalPersonWithLegalIdentificationWithMissingValues,
    contactDetails: ContactDetailsWithoutValues,
    addresses: {},
};

//Legal person
export const LegalPersonWithAllValues: MyProfileData = {
    identification: LegalPersonIdentificationWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    addresses: {
        mainAddress: Address1WithAllValues,
        contactAddress: Address2WithAllValues,
    },
};

export const LegalPersonWithMissingValues: MyProfileData = {
    identification: LegalPersonIdentificationWithMissingValues,
    contactDetails: ContactDetailsWithoutValues,
    addresses: {},
};
