import {
    acceptedConsentDateStorageKey,
    areContactDetailsChangedStorageKey,
    areContactDetailsConfirmedStorageKey,
    isAfterContactDetailsHookPageStorageKey,
    isAfterMarketingConsentHookPageStorageKey,
    isMarketingConsentSubmittedStorageKey,
} from 'config';

export const resetHookPageStorage = (hashedUserId: string) => {
    window.localStorage.setItem(areContactDetailsConfirmedStorageKey, '');
    window.localStorage.setItem(isMarketingConsentSubmittedStorageKey, '');
    window.localStorage.setItem(acceptedConsentDateStorageKey + hashedUserId, '');
    window.localStorage.setItem(areContactDetailsChangedStorageKey, '');
    window.localStorage.setItem(isAfterContactDetailsHookPageStorageKey, '');
    window.localStorage.setItem(isAfterMarketingConsentHookPageStorageKey, '');
};
