import * as Yup from 'yup';
import { MarketingPreference } from '../../apis';
import { defaultValidationMarketingPreferencesErrorMessages } from '../messages/MarketingPreferencesMessages';
import { MarketingPreferencesError } from '../types';

export const marketingPreferencesValidationSchema = (
    errorMessages: MarketingPreferencesError = defaultValidationMarketingPreferencesErrorMessages,
): Yup.SchemaOf<MarketingPreference> =>
    Yup.object().shape({
        directMarketing: Yup.string().required(errorMessages.directMarketing.required),
        indirectMarketing: Yup.string().required(errorMessages.indirectMarketing.required),
    });
